import React, { useEffect } from "react"
import { Field, LazyImage, realPath } from "lib"

const ImgRenderer = ({
    domRef,
    info,
    className,
    value,
    wrap,
    carousel,
    carouselProps,
    ...props
}) => {
    const { fieldInfo, displayInfo } = info
    //console.log(value)
    useEffect(() => {
        if (value && value.length === 0 && fieldInfo.onActive)
            fieldInfo.onActive(value[0], this.props.data)
    }, [fieldInfo, value])

    const imgProps = {}
    imgProps.imageStyle = fieldInfo.imageStyle || displayInfo.imageStyle
    imgProps.bg = fieldInfo.bg || displayInfo.bg
    imgProps.proportional = fieldInfo.proportional || displayInfo.proportional

    if (!value || value.length === 0) return null
    //console.log(value, di)
    return (
        <>
            {value.map((img, i) => {
                if (fieldInfo.itemRenderer) return fieldInfo.itemRenderer(img, i)
                const mime = img.mime ? img.mime.split("/") : "image"
                if (mime[0] === "video")
                    return (
                        <video ref={domRef} className={className} autoPlay playsInline muted loop>
                            <source src={realPath(img.url)} type={img.mime} />
                        </video>
                    )

                if (wrap || fieldInfo.wrap)
                    return (
                        <div key={i} ref={domRef} className={`${className || ""} img`}>
                            <LazyImage src={img} {...imgProps} {...props} />
                        </div>
                    )
                if (img.meta && img.meta.caption) {
                    /*const metaInfo = {
                        value: img.meta,
                        fieldInfo: { type: "obj", fields: info.fieldInfo.meta },
                    }*/
                    return (
                        <div key={i} ref={domRef} className={`${className || ""} img`}>
                            <LazyImage src={img} {...imgProps} {...props} />

                            <Field info={info} field={`${i}.caption`} />
                        </div>
                    )
                }
                return (
                    <LazyImage
                        domRef={domRef}
                        key={i}
                        src={img}
                        className={className}
                        {...imgProps}
                        {...props}
                    />
                )
            })}
        </>
    )
}

export default ImgRenderer
