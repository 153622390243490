//import { ObjectId } from "bson"
import { types, settings, initRec } from "./types"
import classes from "./classes"
import { pathFromField } from "./util"
import { display } from "conf/display"
//import cache from "./cache"
import { flatten } from "../util"

export const $type = Symbol("type")
export const $fieldInfo = Symbol("fieldInfo")
const $itemInfo = Symbol("itemInfo")

export const getSettings = typeInfo => [
    ...(typeInfo?.settings ?? []),
    ...flatten(typeInfo?.typeClasses?.map(tc => settings?.[tc] ?? []) ?? []),
]
const getFieldOrder = (o, typeInfo) => {
    let fields = typeInfo.fields ? typeInfo.fields.map(f => f.name) : []
    fields =
        o && o._e
            ? fields.concat(
                  Object.keys(o._e).filter(f => o._e[f].name && o._e[f].type && !fields.includes(f))
              )
            : fields

    if (!o || !o._o) return fields
    if (typeof o._o === "string") {
        let order = o && o._o && o._o.trim() !== "" ? o._o.split(",") : []
        if (fields.length > 0) return [...new Set(order.concat(fields))]
        return order
    } else {
        const ret = {}
        for (let key in o._o) {
            ret[key] = o._o[key].split(",")
            fields = fields.filter(f => !ret[key].includes(f))
        }
        if (fields.length > 0) {
            if (!ret["content"]) ret["content"] = fields
            else ret["content"] = ret["content"].concat(fields)
        }
        return ret
    }
}

const buildItemTypeInfo = (field, parent, parentInfo, typeClass = "field") => {
    //console.log("buildItemTypeInfo:", field, parent, parentInfo, typeClass)
    /*
    if (!parentInfo.itemInfo) {
        if (parentInfo.items) {
            parentInfo.itemInfo =
                typeof parentInfo.items === "string" ? types[parentInfo.items] : parentInfo.items
        }
        if (!parentInfo.itemInfo) return null
    }
*/
    let typeInfo = parent?.[$fieldInfo]?.[$itemInfo]
    //if (!parent || !parent[$fieldInfo] || !parent[$fieldInfo].itemInfo) {
    if (!typeInfo) {
        const info =
            typeof parentInfo?.items === "string"
                ? types[parentInfo.items]
                : parentInfo?.items ?? {}
        const fieldDef = { ...info, ...(parentInfo?.itemInfo ?? {}) }
        //parentInfo.itemInfo
        //console.log(info, parentInfo, fieldDef)

        if (!fieldDef.type || !types[fieldDef.type]) return null
        typeInfo = { ...fieldDef }
        if (!typeInfo.typeName) typeInfo.typeName = typeInfo.type
        if (!typeInfo.typeClasses || !typeInfo.typeClasses.includes(typeClass))
            typeInfo.typeClasses = [...(typeInfo.typeClasses || []), typeClass]
        //else console.log("TC:", typeInfo.typeClasses)
        typeInfo = initRec(typeInfo)
        typeInfo = {
            ...typeInfo,
            ...typeInfo.typeClasses.reduce((acc, tc) => ({ ...acc, ...classes[tc] }), {}),
            fieldOrder: getFieldOrder(null, typeInfo),
        }
        if (parent) {
            if (!parent[$fieldInfo]) parent[$fieldInfo] = {}
            parent[$fieldInfo][$itemInfo] = typeInfo
        }
    }
    if (!parent || !parent._e || !parent._e[field]) return typeInfo
    const { type, ref, cache, ...conf } = parent._e[field]
    typeInfo = { ...typeInfo, ...conf }
    if (parent) parent[$fieldInfo][field] = typeInfo
    return typeInfo
}
const buildRefFieldTypeInfo = (field, parent, parentInfo, typeClass = "field") => {
    //console.log("TC:", typeClass)
    if (!parentInfo.fieldDefs) {
        const _e = parent && parent._e ? parent._e : {}
        const refInfo = types[parentInfo.ref]
        const cacheFields = parentInfo.cache ? parentInfo.cache.split(",") : []
        const fieldsDef = refInfo.fields || refInfo.meta
        const defaultFields = fieldsDef.map(f => f.name)
        const fields = fieldsDef
            .filter(f => cacheFields.includes(f.name))
            .reduce((acc, f) => ({ ...acc, [f.name]: { ...f, ...(acc[f.name] || {}) } }), _e)

        const fieldsOrder = [...new Set([...defaultFields, ...Object.keys(_e)]).values()]
        parentInfo.defaultFields = defaultFields
        parentInfo.fieldDefs = fieldsOrder
            .map(name => fields[name])
            .filter(f => f && f.type && f.name)
    }
    const fieldDefs = parentInfo.fieldDefs.filter(fd => fd.name === field)
    if (fieldDefs.length === 0) return null
    const fieldDef = fieldDefs[0]

    if (!fieldDef.type || !types[fieldDef.type]) return null
    let typeInfo = { ...fieldDef }
    if (!typeInfo.typeName) typeInfo.typeName = typeInfo.type
    if (!typeInfo.typeClasses) typeInfo.typeClasses = [typeClass]
    //else console.log("TC:", typeInfo.typeClasses)

    typeInfo = initRec(typeInfo)
    typeInfo = {
        ...typeInfo,
        ...typeInfo.typeClasses.reduce((acc, tc) => ({ ...acc, ...classes[tc] }), {}),
    }
    typeInfo.fieldOrder = getFieldOrder(parent ? parent[field] : null, typeInfo)
    if (parent) {
        if (!parent[$fieldInfo]) parent[$fieldInfo] = {}
        parent[$fieldInfo][field] = typeInfo
    }
    return typeInfo
}
const buildMetaFieldTypeInfo = (field, metaField, parent, parentInfo, typeClass = "field") => {
    //console.log("TC:", typeClass)
    if (!parentInfo.fieldDefs) {
        const _e = parent
            ? typeof field !== "undefined"
                ? parent[field] && parent[field].meta && parent[field].meta._e
                    ? parent[field].meta._e
                    : {}
                : parent.meta && parent.meta._e
                ? parent.meta._e
                : {}
            : {}
        const fieldsDef = parentInfo.fields || parentInfo.meta
        const defaultFields = fieldsDef.map(f => f.name)

        const fields = fieldsDef.reduce(
            (acc, f) => ({ ...acc, [f.name]: { ...f, ...(acc[f.name] || {}) } }),
            _e
        )

        const fieldsOrder = [...new Set([...defaultFields, ...Object.keys(_e)]).values()]
        parentInfo.defaultFields = defaultFields
        parentInfo.fieldDefs = fieldsOrder
            .map(name => fields[name])
            .filter(f => f && f.type && f.name)
    }
    const fieldDefs = parentInfo.fieldDefs.filter(fd => fd.name === metaField)
    if (fieldDefs.length === 0) return null
    const fieldDef = fieldDefs[0]

    if (!fieldDef.type || !types[fieldDef.type]) return null
    let typeInfo = { ...fieldDef }
    if (!typeInfo.typeName) typeInfo.typeName = typeInfo.type
    if (!typeInfo.typeClasses) typeInfo.typeClasses = [typeClass]
    //else console.log("TC:", typeInfo.typeClasses)

    typeInfo = initRec(typeInfo)
    typeInfo = {
        ...typeInfo,
        ...typeInfo.typeClasses.reduce((acc, tc) => ({ ...acc, ...classes[tc] }), {}),
        fieldOrder: getFieldOrder(parent ? parent[field] : null, typeInfo),
    }

    if (parent) {
        if (typeof field !== "undefined") {
            if (parent[field]) {
                if (!parent[field].meta) parent[field].meta = {}
                if (!parent[field].meta[$fieldInfo]) parent[field].meta[$fieldInfo] = {}
                parent[field].meta[$fieldInfo][metaField] = typeInfo
            }
        } else {
            if (!parent.meta) parent.meta = {}
            if (!parent.meta[$fieldInfo]) parent.meta[$fieldInfo] = {}
            parent.meta[$fieldInfo][metaField] = typeInfo
        }
    }
    return typeInfo
}
const buildFieldTypeInfo = (field, parent, parentInfo, typeClass = "field") => {
    //console.log("buildFieldTypeInfo", field, parent, parentInfo, typeClass)
    if (!parentInfo.fieldDefs) {
        const _e = parent && parent._e ? parent._e : {}
        const fieldsDef = parentInfo.fields || parentInfo.meta
        const defaultFields = fieldsDef.map(f => f.name)
        const fields = fieldsDef.reduce(
            (acc, f) => ({ ...acc, [f.name]: { ...f, ...(acc[f.name] || {}) } }),
            _e
        )

        const fieldsOrder = [...new Set([...defaultFields, ...Object.keys(_e)]).values()]
        parentInfo.defaultFields = defaultFields
        parentInfo.fieldDefs = fieldsOrder
            .map(name => fields[name])
            .filter(f => f && f.type && f.name)
    }
    const fieldDefs = parentInfo.fieldDefs.filter(fd => fd.name === field)
    if (fieldDefs.length === 0) return null
    const fieldDef = fieldDefs[0]

    if (!fieldDef.type || !types[fieldDef.type]) return null
    let typeInfo = { ...fieldDef }
    if (!typeInfo.typeName) typeInfo.typeName = typeInfo.type
    if (!typeInfo.typeClasses) typeInfo.typeClasses = [typeClass]
    //else console.log("TC:", typeInfo.typeClasses, typeInfo)

    typeInfo = initRec(typeInfo)
    typeInfo = {
        ...typeInfo,
        ...typeInfo.typeClasses.reduce((acc, tc) => ({ ...acc, ...classes[tc] }), {}),
        fieldOrder: getFieldOrder(parent ? parent[field] : null, typeInfo),
    }

    if (parent) {
        if (!parent[$fieldInfo]) parent[$fieldInfo] = {}
        parent[$fieldInfo][field] = typeInfo
    }
    return typeInfo
}
/*
export const buildTypeInfo = (info, parent = null, value = null, typeClass = "field") => {
    //console.log(info, parent, value)
    if (!info || !info.type || !types[info.type]) return null
    let typeInfo = { ...info }
    if (!typeInfo.typeName) typeInfo.typeName = typeInfo.type
    if (!typeInfo.typeClasses) typeInfo.typeClasses = [typeClass]

    typeInfo = initRec(typeInfo)
    typeInfo = {
        ...typeInfo,
        ...typeInfo.typeClasses.reduce((acc, tc) => ({ ...acc, ...classes[tc] }), {}),
    }
    // ???
    if (parent) {
        if (parent._e && parent._e[typeInfo.name]) {
            const { type, ...rest } = parent._e[typeInfo.name]
            typeInfo = { ...typeInfo, ...rest }
        }
    }
    
    typeInfo.fieldInfo = {}

    if (typeInfo.fields || typeInfo.meta) {
        const _e = value && value._e ? value._e : {}
        const fieldsDef = typeInfo.fields || typeInfo.meta
        const defaultFields = fieldsDef.map(f => f.name)
        const fields = fieldsDef.reduce(
            (acc, f) => ({ ...acc, [f.name]: { ...f, ...(acc[f.name] || {}) } }),
            _e
        )

        const fieldsOrder = [...new Set([...defaultFields, ...Object.keys(_e)]).values()]
        typeInfo.defaultFields = defaultFields
        typeInfo.fields = fieldsOrder.map(name => fields[name]).filter(f => f && f.type && f.name)
    }
    return typeInfo
}
*/
/*
parent: e, field, null

config: pathauto
field: f1.f2.text
setting: f1.f2.text.cssid
meta: f1.f2.description
*/
const _typeInfoRecursive = (parent, parentInfo, path, pathIndex) => {
    const field = path[pathIndex]
    const fieldValue = parent ? parent[field] : null
    const lastItem = pathIndex === path.length - 1

    if (parentInfo.meta) {
        //console.log(parent, parentInfo, path, pathIndex)
        if (Number.isInteger(field)) {
            if (!lastItem) {
                const metaField = path[pathIndex + 1]
                let fieldInfo =
                    parent && parent[field] && parent[field].meta && parent[field].meta[$fieldInfo]
                        ? parent[field].meta[$fieldInfo][metaField]
                        : null
                if (!fieldInfo)
                    fieldInfo = buildMetaFieldTypeInfo(field, metaField, parent, parentInfo)
                if (fieldInfo) {
                    if (pathIndex === path.length - 2) return fieldInfo
                    const metaValue = fieldValue ? fieldValue[metaField] : null
                    return _typeInfoRecursive(metaValue, fieldInfo, path, pathIndex + 2)
                }
            }
            return {
                ...types.obj,
                fields: parentInfo.fields,
                meta: parentInfo.meta,
                dummy: true,
                typeClasses: ["meta"],
                ...classes.meta,
            }
        } else {
            if (!parentInfo.dummy) {
                let fieldInfo =
                    parent && parent.meta && parent.meta[$fieldInfo]
                        ? parent.meta[$fieldInfo][field]
                        : null
                if (!fieldInfo)
                    fieldInfo = buildMetaFieldTypeInfo(undefined, field, parent, parentInfo)
                if (fieldInfo) {
                    if (lastItem) return fieldInfo
                    return _typeInfoRecursive(
                        parent && parent.meta ? parent.meta[field] : null,
                        fieldInfo,
                        path,
                        pathIndex + 1
                    )
                }
            }
        }
    } else {
        switch (parentInfo.type) {
            case "obj": {
                let fieldInfo = parent && parent[$fieldInfo] ? parent[$fieldInfo][field] : null
                if (!fieldInfo) fieldInfo = buildFieldTypeInfo(field, parent, parentInfo)
                if (fieldInfo) {
                    if (lastItem) return fieldInfo
                    return _typeInfoRecursive(fieldValue, fieldInfo, path, pathIndex + 1)
                }
                break
            }
            case "ref": {
                let fieldInfo = parent && parent[$fieldInfo] ? parent[$fieldInfo][field] : null
                if (!fieldInfo) fieldInfo = buildRefFieldTypeInfo(field, parent, parentInfo)
                if (fieldInfo) {
                    if (lastItem) return fieldInfo
                    return _typeInfoRecursive(fieldValue, fieldInfo, path, pathIndex + 1)
                }
                break
            }
            case "list": {
                if (Number.isInteger(path[pathIndex])) {
                    let itemInfo = parent && parent[$fieldInfo] ? parent[$fieldInfo][field] : null
                    if (!itemInfo) itemInfo = buildItemTypeInfo(field, parent, parentInfo)
                    if (itemInfo) {
                        if (lastItem) return itemInfo
                        return _typeInfoRecursive(fieldValue, itemInfo, path, pathIndex + 1)
                    }
                }
                break
            }
            default:
                break
        }
    }
    /*
    let settingInfo = parent && parent[$settingsInfo] ? parent[$settingsInfo][field] : null
    if (!settingInfo) {
        const settingsList = getSettings(parentInfo).filter(f => f.name === field)
        if (settingsList.length > 0) {
            settingInfo = {
                ...settingsList[0],
                ...settingsList[0].typeClasses.reduce(
                    (acc, tc) => ({ ...acc, ...classes[tc] }),
                    {}
                ),
                fieldOrder: getFieldOrder(null, settingsList[0]),
            }

            if (parent) {
                if (!parent[$settingsInfo]) parent[$settingsInfo] = {}
                parent[$settingsInfo][field] = settingInfo
            }
        }
    }
    if (settingInfo) {
        if (lastItem) return settingInfo
        return _typeInfoRecursive(parentInfo[field], settingInfo, path, pathIndex + 1)
    }*/

    const settingsList = getSettings(parentInfo).filter(f => f.name === field)
    if (settingsList.length > 0) {
        const child = parentInfo[field]
        const fieldInfo = settingsList[0]
        //console.log(fieldInfo)
        if (pathIndex === path.length - 1) return fieldInfo
        return _typeInfoRecursive(child, fieldInfo, path, pathIndex + 1)
    }

    return null

    //if (path[path.length - 1] === "caption") console.log(parent, parentInfo, path, pathIndex)
    /*
    if (parentInfo.meta) {
        if (Number.isInteger(path[pathIndex])) {
            if (pathIndex < path.length - 1) {
                const index = path[pathIndex]
                const field = path[pathIndex + 1]
                let typeInfo = parentInfo.fieldInfo[field]
                if (!typeInfo) {
                    const fields = parentInfo.fields.filter(f => f.name === field)
                    if (fields.length !== 0) {
                        typeInfo = fields[0]
                    }
                }
                if (typeInfo) {
                    if (pathIndex === path.length - 2) return typeInfo
                    return _typeInfoRecursive(
                        parent && parent[index] && parent[index].meta
                            ? parent[index].meta[field]
                            : null,
                        typeInfo,
                        path,
                        pathIndex + 2
                    )
                }
            }
            return {
                ...types.obj,
                fields: parentInfo.fields,
                meta: parentInfo.meta,
                typeClasses: ["meta"],
            }
        }
    }
    switch (parentInfo.type) {
        case "ref": {
            if (!parentInfo.ref) {
                console.error("Parent is of type 'ref', but not ref is defined.")
                return null
            }
            const refInfo = types[parentInfo.ref]
            if (!refInfo) {
                console.error(
                    `Parent is of type 'ref' and ref is of type ${parentInfo.ref}, but no such type is defined.`
                )
                return null
            }
            const settingsList = getSettings(parentInfo).filter(f => f.name === path[pathIndex])
            if (settingsList.length > 0) {
                const child = parentInfo[path[pathIndex]]
                const fieldInfo = settingsList[0]
                if (pathIndex === path.length - 1) return fieldInfo
                return _typeInfoRecursive(child, fieldInfo, path, pathIndex + 1)
            }
            return _typeInfoRecursive(parent, refInfo, path, pathIndex)
        }
        case "list":
            if (Number.isInteger(path[pathIndex])) {
                const listIndex = path[pathIndex]
                const item = parent ? parent[listIndex] : null
                let itemInfo = parentInfo.fieldInfo[listIndex]
                if (!itemInfo) {
                    const info = types[parentInfo.items]
                    if (parentInfo.items === "ref") {
                        info.ref = parentInfo.ref
                        info.cache = parentInfo.cache
                    }
                    itemInfo = buildTypeInfo(
                        {
                            ...info,
                            ...(parentInfo.itemInfo || {}),
                            ...(parentInfo._items && parentInfo._items[listIndex]
                                ? parentInfo._items[listIndex]
                                : []),
                        },
                        parent,
                        item
                    )
                    parentInfo.fieldInfo[listIndex] = itemInfo
                }
                if (pathIndex === path.length - 1) return itemInfo
                return _typeInfoRecursive(item, itemInfo, path, pathIndex + 1)
            } else {
                const itemsInfo = buildTypeInfo({ type: parentInfo.items }, parent)
                if (pathIndex === path.length - 1) return itemsInfo
                const item = parent ? parent[0] : null
                return _typeInfoRecursive(item, itemsInfo, path, pathIndex + 1)
            }
        case "obj": {
            const field = path[pathIndex]
            const value = parent ? parent[field] : null
            let typeInfo = parentInfo.fieldInfo[field]
            if (!typeInfo) {
                //console.log(path, pathIndex, parent, parentInfo)
                const fields = parentInfo.fields.filter(f => f.name === field)
                if (fields.length > 0) {
                    typeInfo = buildTypeInfo(fields[0], parent, value)
                    parentInfo.fieldInfo[field] = typeInfo
                }
            }
            //console.log(path, pathIndex, field, parentInfo, typeInfo, value)
            if (typeInfo) {
                if (pathIndex === path.length - 1) return typeInfo
                return _typeInfoRecursive(value, typeInfo, path, pathIndex + 1)
            }
            break
        }
        default:
            break
    }
    const settingsList = getSettings(parentInfo).filter(f => f.name === path[pathIndex])
    if (settingsList.length > 0) {
        const child = parentInfo[path[pathIndex]]
        const fieldInfo = settingsList[0]
        if (pathIndex === path.length - 1) return fieldInfo
        return _typeInfoRecursive(child, fieldInfo, path, pathIndex + 1)
    }
    return null
*/
}

export const getTypeInfo = (object, parent = null, parentInfo = null) => {
    //if (object === "f2") console.log(object, typeof object, parent, parentInfo)
    if (!object) return null
    if (typeof object === "string") {
        //console.log(object, parent, parentInfo)
        // f1.f2.cssid -> f1._e.f2.cssid
        let pInfo = parentInfo
        if (!pInfo) {
            // parent is entity
            if (!parent) return null
            pInfo = getTypeInfo(parent)
            if (!pInfo) return null
        }
        //return null
        //if (!pInfo.fieldInfo) pInfo.fieldInfo = {}
        //if (pInfo.fieldInfo[object]) return pInfo.fieldInfo[object]
        //console.log(pInfo)
        const fieldInfo = _typeInfoRecursive(parent, pInfo, pathFromField(object), 0)
        //if (!pInfo.fieldInfo) pInfo.fieldInfo = {}
        //pInfo.fieldInfo[object] = fieldInfo
        //console.log(fieldInfo)
        //if (object === "f2") console.log(fieldInfo)
        return fieldInfo
    }
    if (object[$type]) return object[$type]
    if (!object.type && !object._id) return null
    if (!types[object.type]) return null
    //if (cache.has(object._id)) return cache.get(object._id)
    //console.log("INFO", object._id.toString())
    const typeInfo = types[object.type]
    //console.log(object, typeInfo)
    /*const defaultFields = typeInfo.fields.map(f => f.name)
    const fields = typeInfo.fields.reduce(
        (acc, f) => ({ ...acc, [f.name]: { ...f, ...(acc[f.name] || {}) } }),
        object._e || {}
    )
    const fieldsOrder = [...new Set([...defaultFields, ...Object.keys(object._e || {})]).values()]
    */
    const info = {
        ...typeInfo,
        ...(object._c || {}),
        //defaultFields,
        //fieldInfo: {},
        //fields: fieldsOrder
        //    .map(name => buildTypeInfo(fields[name], object, object[name]))
        //    .filter(f => f && f.name && f.type),
    }
    info.fieldOrder = getFieldOrder(object, info)
    //console.log(info)
    //console.log(object, info)
    //cache.set(object._id, info)
    object[$type] = info
    return info
}

export const resetInfo = e => {
    if (!e) return e
    return { ...e, $type: undefined, $fieldInfo: undefined }
}

export const getCollection = entity => {
    if (!entity || !entity.type || !types[entity.type]) return null
    return types[entity.type].collection
}
export const is = (entity, typeClass) => {
    if (!entity || !entity.type || !types[entity.type]) return null
    return types[entity.type].typeClasses.includes(typeClass)
}
export const getDisplayInfo = (entity, displayName) => {
    const info = getTypeInfo(entity)
    const displayInfo = display[displayName || "default"] || {}
    const entityDisplayInfo = displayInfo[entity.type]
    const ret = {}
    if (displayInfo && displayInfo._layout) ret._layout = displayInfo._layout
    if (entityDisplayInfo && entityDisplayInfo._layout) ret._layout = entityDisplayInfo._layout
    if (!info) return ret
    //console.log(info, entity)
    const fo = Array.isArray(info.fieldOrder)
        ? info.fieldOrder
        : flatten(Object.values(info.fieldOrder))
    //console.log(fo)
    fo.forEach(fieldName => {
        //console.log(field)
        const field = getTypeInfo(fieldName, entity)
        if (!field) return
        if (!entityDisplayInfo) {
            if (!!displayName) {
                //console.log(field.name, displayInfo[field.name])
                if (!displayInfo[field.name]) return
                if (displayInfo[field.name] === true) {
                    ret[field.name] = {
                        ...(displayInfo[field.typeName] || {}),
                    }
                    return
                }
                ret[field.name] = {
                    ...(displayInfo[field.typeName] || {}),
                    ...(displayInfo[field.name] || {}),
                }
            } else {
                if (displayInfo[field.name] === false) return
                ret[field.name] = {
                    ...(displayInfo[field.typeName] || {}),
                    ...(displayInfo[field.name] || {}),
                }
            }
            return
        }
        //console.log(displayName, info, displayInfo, entityDisplayInfo, field)
        if (
            entityDisplayInfo[field.name] === false ||
            (displayInfo[field.name] === false && !entityDisplayInfo[field.name])
        )
            return
        //console.log("2", field.name, displayInfo[field.name], entityDisplayInfo[field.name])
        if (!!displayName) {
            if (!entityDisplayInfo[field.name]) return
            if (entityDisplayInfo[field.name] === true) {
                ret[field.name] = {
                    ...(displayInfo[field.typeName] || {}),
                    ...(displayInfo[field.name] || {}),
                }
                return
            }
        }
        ret[field.name] = {
            ...(displayInfo[field.typeName] || {}),
            ...(displayInfo[field.name] || {}),
            ...(entityDisplayInfo[field.typeName] || {}),
            ...(entityDisplayInfo[field.name] || {}),
        }
    })
    //console.log(displayName, info, displayInfo, entityDisplayInfo, ret)
    return ret
}
export const getFieldDisplayInfo = (entity, field, displayName) => {
    const displayInfo = display[displayName || "default"] || {}
    if (!field || !entity) return {}
    const entityDisplayInfo = displayInfo[entity.type]
    //console.log(entity, field, displayName, displayInfo, entityDisplayInfo)
    if (!entityDisplayInfo) {
        if (!!displayName) {
            //console.log(field.name, displayInfo[field.name])
            if (!displayInfo[field.name]) return false
            if (displayInfo[field.name] === true) {
                return {
                    ...(displayInfo[field.typeName] || {}),
                }
            }
            return {
                ...(displayInfo[field.typeName] || {}),
                ...(displayInfo[field.name] || {}),
            }
        } else {
            if (displayInfo[field.name] === false) return false
            return {
                ...(displayInfo[field.typeName] || {}),
                ...(displayInfo[field.name] || {}),
            }
        }
    }

    if (
        entityDisplayInfo[field.name] === false ||
        (displayInfo[field.name] === false && !entityDisplayInfo[field.name])
    )
        return false
    if (!!displayName) {
        if (!entityDisplayInfo[field.name]) return false
        if (entityDisplayInfo[field.name] === true) {
            return {
                ...(displayInfo[field.typeName] || {}),
                ...(displayInfo[field.name] || {}),
            }
        }
    }
    return {
        ...(displayInfo[field.typeName] || {}),
        ...(displayInfo[field.name] || {}),
        ...(entityDisplayInfo[field.typeName] || {}),
        ...(entityDisplayInfo[field.name] || {}),
    }
}
