import React from "react"
import { Transition, TransitionGroup } from "react-transition-group"
import { LazyImage, useQuery, useInterval } from "lib"

const q = {
    collection: "bg",
    query: {},
}
const useData = () => {
    const [state, setState] = React.useState({})
    const [data] = useQuery(q)
    const changePic = React.useCallback(() => {
        const images = data?.[0]?.img
        if (!images || images.length === 0) return
        setState(state => {
            if (typeof state.index === "undefined") return { index: 0, img: images[0] }
            let index = state.index + 1
            if (index >= images.length) index = 0
            return { index, img: images[index] }
        })
    }, [data])
    React.useEffect(() => {
        changePic()
    }, [data, changePic])
    useInterval(changePic, 15000)
    return state?.img
}
const Bg = () => {
    const img = useData()

    return (
        <TransitionGroup id="site-bg" appear={true} enter={true}>
            <Transition key={img?.url ?? "none"} unmountOnExit={true} timeout={1000}>
                {status => (img ? <LazyImage className={`bg-${status}`} src={img} bg /> : null)}
            </Transition>
        </TransitionGroup>
    )
}
export default React.memo(Bg)
