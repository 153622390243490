//import { realPath } from "lib"
import { documentStateTypes, positionTypes } from "./config"

const entityFields = {
    homepage: [],

    NotFound: [
        { name: "title", type: "text", label: "Titlu" },
        { name: "body", type: "html", label: "Conținut" },
    ],
    panel: [
        { name: "title", type: "text", label: "Titlu" },
        { name: "antet", type: "ref", ref: "antet", label: "Antet" },
    ],
    page: [
        { name: "title", type: "text", label: "Titlu" },
        { name: "date", type: "date", label: "Data" },
        { name: "year", type: "int", label: "An" },
        { name: "subtitle", type: "text", label: "Subtitlu" },
        { name: "body", type: "html", label: "Conținut" },
        {
            name: "cat",
            type: "ref",
            ref: "category",
            label: "Categorie",
            cache: "title,path_info,hasperson",
        },
        { name: "thumb", type: "img", label: "Miniatură", single: true },
        { name: "thumb_front", type: "img", label: "Miniatură", single: true },
        { name: "summary", type: "text", label: "Sumar" },
        { name: "ord", type: "int", label: "Ordine" },
        { name: "docs", type: "doc", label: "Documente" },
        { name: "antet", type: "ref", ref: "antet", label: "Antet" },
    ],

    document: [
        { name: "title", type: "text", label: "Titlu" },
        { name: "subtitle", type: "text", label: "Subtitlu" },
        { name: "sticky", type: "bool", label: "Promovat" },
        {
            name: "cat",
            type: "ref",
            ref: "category",
            label: "Categorie",
            cache: "title,path_info,hasperson,hasstate",
            refOptions: {
                searchQuery: { hascontent: 1 },
                sort: { group: 1, title: 1 },
                group: option => option.group,
                disabled: option => !option.hascontent,
            },
        },
        {
            name: "person",
            type: "ref",
            ref: "person",
            label: "Persoana",
            cache: "firstName,lastName,position",
        },
        {
            name: "state",
            type: "select",
            values: documentStateTypes,
            label: "Stare",
        },
        { name: "year", type: "int", label: "An" },
        { name: "date", type: "date", label: "Data" },
        { name: "showTime", type: "bool", label: "Afișează ora" },
        { name: "docs", type: "doc", label: "Document" },
        { name: "body", type: "html", label: "Conținut" },
    ],
    docList: [
        { name: "title", type: "text", label: "Titlu" },
        { name: "body", type: "html", label: "Introducere" },
        //{ name: "kind", type: "select", values: documentTypes, label: "Tip", renderer: "DocList" },
        {
            name: "cat",
            type: "ref",
            ref: "category",
            label: "Categorie",
            cache: "title,path_info,hasperson,hasstate",
            renderer: "DocList",
            _nowrap: true,
            _visibility: true,
            refOptions: {
                searchQuery: { hascontent: 1 },
                sort: { group: 1, title: 1 },
                group: option => option.group,
                disabled: option => !option.hascontent,
            },
        },
        { name: "position", type: "select", values: positionTypes, label: "Functia" },
        { name: "nofilter", type: "bool", label: "Fără Filtru anual" },
        { name: "promoted", type: "bool", label: "Promovat" },
        { name: "display", type: "text", label: "Afișare" },
        { name: "summary", type: "text", label: "Sumar" },
        { name: "thumb", type: "img", label: "Miniatură", single: true },
    ],

    article: [
        { name: "title", type: "text", label: "Titlu" },
        { name: "subtitle", type: "text", label: "Titlu" },
        { name: "date", type: "date", label: "Data" },
        { name: "year", type: "int", label: "An" },
        { name: "body", type: "html", label: "Conținut" },
        { name: "nr", type: "text", label: "Nr" },
        {
            name: "cat",
            type: "ref",
            ref: "category",
            label: "Categorie",
            cache: "title,path_info,hasperson,hasstate",
            refOptions: {
                searchQuery: { hascontent: 1 },
                sort: { group: 1, title: 1 },
                group: option => option.group,
                disabled: option => !option.hascontent,
            },
            renderer: "DocList",
            _visibility: true,
        },
        { name: "img", type: "img", label: "Imagine" },
        { name: "summary", type: "text", label: "Sumar" },
        { name: "thumb", type: "img", label: "Miniatură", single: true },
        { name: "docs", type: "doc", label: "Documente" },
    ],
    person: [
        { name: "firstName", type: "text", label: "Prenume" },
        { name: "lastName", type: "text", label: "Nume" },
        { name: "position", type: "select", values: positionTypes, label: "Funcţie" },
        { name: "img", type: "img", label: "Imagine" },
        { name: "body", type: "html", label: "Prezentare" },
        { name: "active", type: "bool", label: "Activ" },
    ],

    category: [
        { name: "title", type: "text", label: "Titlu" },
        { name: "hascontent", type: "bool", label: "Conținut" },
        { name: "path_info", type: "text", computed: true, label: "Pathinfo" },
        { name: "hasperson", type: "bool", label: "Persoana" },
        { name: "hasstate", type: "bool", label: "Stare" },
        {
            name: "parent",
            type: "ref",
            ref: "category",
            label: "Părinte",
            cache: "title,group,path_info",
        },
        { name: "group", type: "text", computed: true, label: "Secțiune" },
    ],
    folder: [
        { name: "title", type: "text", label: "Titlu" },
        {
            name: "folder",
            type: "computed",
            label: "Dosar",
            renderer: "Folder",
            _nowrap: true,
        },
        { name: "summary", type: "text", label: "Sumar" },
        { name: "thumb", type: "img", label: "Miniatură", single: true },
    ],

    menu: [
        { name: "label", type: "text", label: "Nume" },
        { name: "items", type: "list", items: "menuItem", label: "Elemente" },
    ],
    footer: [],
    antet: [
        { name: "title", type: "text", label: "Titlu" },
        { name: "img", type: "img", label: "Imagini" },
        { name: "default", type: "bool", label: "Implicit" },
    ],
    header: [{ name: "text", type: "html", label: "Text" }],
    bg: [{ name: "img", type: "img", label: "Imagini" }],
}
export { entityFields }
