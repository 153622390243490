import React from "react"
import { FaIcon, Link } from "lib"

const renderIcon = props => {
    if (!props) return null
    if (typeof props === "string") return <FaIcon icon={props} size={24} />
    return <FaIcon {...props} />
}

const LinkRenderer = ({ domRef, info, value, iconBefore, iconAfter, children, ...props }) => {
    if (!value) return null
    //console.log(info)
    return (
        <Link domRef={domRef} {...props} to={value.path || ""}>
            {renderIcon(iconBefore || info.fieldInfo.iconBefore)}
            {value.label || ""}
            {children}
            {renderIcon(iconAfter || info.fieldInfo.iconAfter)}
        </Link>
    )
}
export default React.memo(LinkRenderer)
