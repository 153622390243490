import React from "react"
import Link from "../Link"
import FaIcon from "../FaIcon"
import { useAccessibility } from "../../hooks"
import C from "../../conf"

const renderValue = (value, children) => {
    let val = `${value}`
    if (!value || val.trim() === "") return null
    const toks = val.split("®")
    if (toks.length === 1)
        return (
            <>
                {val}
                {children}
            </>
        )
    return toks.map((text, i) => {
        if (i === 0)
            return (
                <>
                    {text}
                    {children}
                </>
            )
        return (
            <React.Fragment key={i}>
                <sup>®</sup>
                {text}
            </React.Fragment>
        )
    })
}
const renderIcon = props => {
    if (!props) return null
    if (typeof props === "string") return <FaIcon icon={props} size={24} />
    return <FaIcon {...props} />
}
const Text = ({ info, value, pre, post, linkTo, iconBefore, iconAfter, children }) => {
    //console.log(info)
    const { fieldInfo } = info
    const a11y = useAccessibility()
    const onAudio = React.useCallback(() => {
        //console.log("audio")
        document.getElementById("a11y-audio").src = `${C.BASE}/audio/${fieldInfo.a11y}.mp3`
    }, [fieldInfo.a11y])
    const wrapperProps = {}
    if (a11y && a11y.audio && fieldInfo.a11y) {
        wrapperProps.onMouseOver = onAudio
    }

    //if (!value || typeof value !== "string" || value.trim() === "") return null
    if (info.displayInfo.link && info.entity.path)
        return (
            <Link to={info.entity.path} {...wrapperProps}>
                {pre}
                {renderIcon(iconBefore || info.fieldInfo.iconBefore || info.displayInfo.iconBefore)}
                {renderValue(value, children)}
                {renderIcon(iconAfter || info.fieldInfo.iconAfter || info.displayInfo.iconAfter)}
                {post}
            </Link>
        )
    const lt = linkTo || info.displayInfo.linkTo
    if (lt)
        return (
            <Link to={lt} {...wrapperProps}>
                {pre}
                {renderIcon(iconBefore || info.fieldInfo.iconBefore || info.displayInfo.iconBefore)}
                {renderValue(value, children)}
                {renderIcon(iconAfter || info.fieldInfo.iconAfter || info.displayInfo.iconAfter)}
                {post}
            </Link>
        )
    return (
        <>
            {pre}
            {renderIcon(iconBefore || info.fieldInfo.iconBefore || info.displayInfo.iconBefore)}
            {renderValue(value, children)}
            {renderIcon(iconAfter || info.fieldInfo.iconAfter || info.displayInfo.iconAfter)}
            {post}
        </>
    )
}
export default Text
