import React from "react"
import { EntityView, Field, useQuery, Link, realPath } from "lib"
import MainMenu, { useMainMenu } from "./MainMenu"
import MobileMenu from "./MobileMenu"
import SearchBar from "./SearchBar"
const qHeader = {
    collection: "site",
    query: {
        type: "header",
    },
}
const useHeader = () => {
    const [headers] = useQuery(qHeader)
    return headers && headers.length > 0 ? headers[0] : null
}

const Header = ({ node, language, first }) => {
    const header = useHeader()
    const menu = useMainMenu()
    return (
        <header role="banner" id="header" key="header">
            <Link id="logo" to="/">
                <img src={realPath("/img/sign-feldru-1.png")} alt="Logo" className="sign" />
                <img src={realPath("/img/logo-feldru-vert.svg")} alt="Logo" className="logo" />
            </Link>

            <div className="header-in">
                <MainMenu id="main-menu" menu={menu} language={language} />
                <MobileMenu menu={menu} />
                <SearchBar/>
            </div>
            {header && (
                <EntityView entity={header}>
                    {info => <Field info={info} field="text" />}
                </EntityView>
            )}
        </header>
    )
}
export default React.memo(Header)

