const formFieldTypes = [
    { val: "text", label: "Text" },
    { val: "email", label: "Adresă e-mail" },
    { val: "textarea", label: "Text lung" },
    { val: "captcha", label: "Captcha" },
    { val: "submit", label: "Trimite" },
]
const types = {
    atom: {
        label: "Atom",
        type: "atom",
    },
    /*none: {
        label: "Nimic",
        type: "none",
    },
    variant: {
        label: "Variant",
        type: "variant",
        variants: [],
    },*/
    computed: {
        label: "Rezultat",
        type: "computed",
        computed: true,
        getLabel: value => value,
    },
    text: {
        label: "Text simplu",
        type: "text",
        getLabel: value => value,
        settings: [
            { name: "linkTo", type: "text", label: "Legătură către" },
            { name: "iconBefore", type: "ricon", label: "Iconiță în față" },
            { name: "iconAfter", type: "ricon", label: "Iconiță în spate" },
        ],
    },
    int: {
        label: "Număr întreg",
        type: "int",
        getLabel: value => value,
    },
    number: {
        label: "Număr",
        type: "number",
        getLabel: value => value,
    },
    html: {
        label: "HTML",
        type: "html",
        getLabel: value =>
            value
                ? typeof value === "string"
                    ? value
                    : value.raw && value.raw.blocks && value.raw.blocks.length > 0
                    ? value.raw.blocks[0].text
                    : ""
                : "",
    },
    date: {
        label: "Data",
        type: "date",
        setValue: value => {
            if (value === "now") return new Date()
            return value
        },
        settings: [{ name: "showTime", type: "bool", label: "Data și Ora", widget: "toggle" }],
    },
    img: {
        label: "Imagine",
        type: "img",
        getLabel: value => (value && value.length > 0 ? value[0].name : ""),
        settings: [
            { name: "_carousel", type: "bool", label: "Carusel", widget: "toggle" },
            {
                name: "_slideshow",
                type: "obj",
                label: "Slideshow",
                fields: [{ name: "i", type: "int", label: "Interval", end: "ms" }],
                option: true,
            },
            { name: "bg", type: "bool", label: "Fundal", require: "admin" },
            {
                name: "wrap",
                type: "bool",
                label: "Containere individuale",
                require: "admin",
            },
            {
                name: "proportional",
                type: "bool",
                label: "Proporțional",
                require: "admin",
            },
        ],
    },
    vid: {
        label: "Video",
        type: "vid",
        settings: [
            {
                name: "playsInline",
                type: "bool",
                label: "PlaysInline",
                default: true,
                widget: "toggle",
            },
            { name: "controls", type: "bool", label: "Controls", default: true, widget: "toggle" },
            { name: "muted", type: "bool", label: "Muted", widget: "toggle" },
            { name: "autoPlay", type: "bool", label: "AutoPlay", widget: "toggle" },
            { name: "loop", type: "bool", label: "Loop", widget: "toggle" },
        ],
        getLabel: value =>
            value ? (value.length === 1 ? value[0].name : `${value.length} fișiere`) : "",
    },
    video: {
        label: "Link Video",
        type: "video",
        getLabel: value => value,
    },
    doc: {
        label: "Doc",
        type: "doc",
        getLabel: value => value,
    },
    bool: {
        label: "Bool",
        type: "bool",
        getLabel: value => value,
    },
    class: {
        label: "Clasă",
        type: "class",
        getLabel: value => value,
    },
    radio: {
        label: "Butoane radio",
        type: "radio",
    },
    select: {
        label: "Selector",
        type: "select",
    },
    list: {
        label: "Listă",
        type: "list",
        /*settings: [
            {
                name: "itemInfo",
                type: "obj",
                label: "Item Info",
                fields: [{ name: "fields", type: "list", items: "atom", label: "Câmpuri" }],
            },
        ],*/
    },
    obj: {
        label: "Obiect",
        type: "obj",
    },
    custom: {
        label: "Personalizat",
        type: "custom",
    },
    ref: {
        label: "Reference",
        type: "ref",
    },
    ricon: {
        label: "Remote Icon",
        type: "obj",
        fields: [
            { name: "icon", type: "text", label: "Nume" },
            { name: "size", type: "int", label: "Mărime" },
        ],
        renderer: "RemoteIcon",
        _nowrap: true,
    },
    form: {
        label: "Formular",
        type: "obj",
        isBlock: true,
        renderer: "Form",
        _nowrap: true,
        fields: [
            { name: "name", type: "text", label: "Nume", fullWidth: true },
            { name: "sendMail", type: "bool", label: "Trimite mesaj" },
            //{ name: "address", type: "text", label: "Adresa", fullWidth: true },
            //{ name: "subject", type: "text", label: "Subiect", fullWidth: true },
        ],
        states: [
            { val: "open", label: "Deschis" },
            { val: "sending", label: "Se trimite" },
            { val: "sent", label: "Trimis" },
        ],
    },
    "form-field": {
        label: "Rubrică Formular",
        type: "obj",
        fields: [
            { name: "label", type: "text", label: "Etichetă", fullWidth: true },
            {
                name: "fieldType",
                type: "select",
                label: "Tip",
                values: formFieldTypes,
            },
            { name: "mandatory", type: "bool", label: "Obligatoriu" },
            //{ name: "sendTo", type: "bool", label: "Trimite mesaj" },
        ],
        renderer: "FormField",
        _nowrap: true,
        /*conditional: [
            [
                v => v.fieldType === "email" && v._e && v._e.sendTo && v._e.sendTo._hidden,
                v => ({
                    ...v,
                    _e: {
                        ...v._e,
                        sendTo: { ...(v._e && v._e.sendTo ? v._e.sendTo : {}), _hidden: false },
                    },
                }),
            ],
            [
                v => v.fieldType !== "email",
                v => ({
                    ...v,
                    _e: {
                        ...(v._e || {}),
                        sendTo: {
                            ...(v._e && v._e.sendTo ? v._e.sendTo : {}),
                            _hidden: true,
                        },
                    },
                }),
            ],
        ],*/
    },

    view: {
        label: "Vizualizare",
        type: "obj",
        fields: [
            { name: "title", type: "text", label: "Titlu" },
            { name: "view", type: "text", label: "Vizualizare" },
            { name: "collection", type: "text", label: "Collection" },
            { name: "query", type: "text", label: "Interogare" },
            { name: "projection", type: "text", label: "Proiecție" },
            { name: "sort", type: "text", label: "Ordonare" },
            { name: "limit", type: "text", label: "Limită" },
            { name: "pipeline", type: "text", label: "Pipeline", multiLine: true, rows: 5 },
            { name: "display", type: "text", label: "Afișare" },
            { name: "pageSize", type: "text", label: "Pagină" },
            { name: "moreLink", type: "href", label: "Legătură 'Mai mult...'" },
            { name: "moreText", type: "text", label: "Text 'Mai mult...'" },
        ],
        //isEmpty: value => !value || !value.path || value.path === "",
        _nowrap: true,
        renderer: "View",
    },
    seo: {
        label: "SEO",
        type: "obj",
        fields: [
            { name: "title", type: "text", label: "Titlu", fullWidth: true },
            {
                name: "description",
                type: "text",
                label: "Descriere",
                fullWidth: true,
                multiline: true,
                rows: 3,
            },
            { name: "image", type: "img", label: "Imagine" },
        ],
        widgetProps: { nolabel: true },
    },
}
export default types
