import React from "react"
import { C, useQuery, Link } from "lib"

const mainMenuQuery = {
    collection: "menu",
    query: {
        label: "Meniu principal",
    },
}
export const useMainMenu = () => {
    const [mainMenu] = useQuery(mainMenuQuery, { single: true, tag: "mainMenu" })
    return mainMenu ? mainMenu.items : []
}

const MenuItem = ({ item, i, language, onActivate }) => {
    const hasI18n = item._i18n
        ? Object.keys(item._i18n).filter(l => l === language).length > 0
        : false
    const data = hasI18n ? item._i18n[language] : item
    const onHover = React.useCallback(() => {
        const a11y = item?._e?.title?.a11y
        if (!a11y) return
        document.getElementById("a11y-audio").src = `${C.BASE}/audio/${a11y}.mp3`
    }, [item])
    return (
        <li className={item.items && item.items.length > 0 ? "has-submenu" : ""}>
            <Link to={data.p} onActivate={onActivate} onMouseOver={onHover}>
                {data.title}
            </Link>
            {item.items && (
                <ul className="menu">
                    {item.items.map((item, i) => (
                        <MenuItem
                            item={item}
                            key={i}
                            i={i}
                            language={language}
                            onActivate={onActivate}
                        />
                    ))}
                </ul>
            )}
        </li>
    )
}
//renderLink(item, i, language, onActivate)

const MainMenu = ({ id, menu, language, onActivate }) => (
    <ul id={id} className="main-menu">
        {menu.map((item, i) => (
            <MenuItem item={item} key={i} i={i} language={language} onActivate={onActivate} />
        ))}
    </ul>
)

export default React.memo(MainMenu)
