export const documentStateTypes = [
    { val: "1", label: "În desfăşurare" },
    { val: "2", label: "Atribuită" },
    { val: "3", label: "Anulată" },
]

export const positionTypes = [
    { val: "1", label: "Primar" },
    { val: "2", label: "Viceprimar" },
    { val: "3", label: "Consilier Local" },
    { val: "4", label: "Funcţionar public" },
]
