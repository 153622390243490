import React, { useRef, useCallback } from "react"
import { useUnmounted } from "../../hooks"
import { useIntersection } from "../../intersection"
import LazyImage from "../LazyImage"
import Link from "../Link"
import FaIcon from "../FaIcon"
import PullQuote from "./PullQuote"

const BLOCK_TAGS = {
    paragraph: "p",
    "list-item": "li",
    "bulleted-list": "ul",
    "numbered-list": "ol",
    quote: "blockquote",
    code: "pre",
    "heading-one": "h1",
    "heading-two": "h2",
    "heading-three": "h3",
    "heading-four": "h4",
    "heading-five": "h5",
    "heading-six": "h6",
    div: "div",
    //iframe: "iframe",
}
const MARK_TAGS = {
    bold: "strong",
    italic: "em",
    underline: "u",
    strikethrough: "s",
    code: "code",
}
const getText = node => {
    if (node.object === "text")
        return node.hasOwnProperty("text")
            ? node.text
            : node.leaves.reduce((acc, leaf) => acc + leaf.text, "")
    //console.log(node)
    return node.nodes.reduce((acc, n) => acc + getText(n), "")
}

const getBlockTag = block => {
    const blockProps = {}
    if (block.data && block.data.style) blockProps.style = block.data.style
    if (block.data && block.data.className) blockProps.className = block.data.className
    if (block.type !== "paragraph") return [BLOCK_TAGS[block.type], blockProps]
    const attachments = block.nodes.filter(n => n.object === "inline" && n.type === "attach")

    if (attachments.length > 0) {
        const text = getText(block)
        blockProps.className = "p with-attachment"
        if (text.trim() === "") blockProps.className += " text-empty"
        return ["div", blockProps]
    }
    return [BLOCK_TAGS[block.type], blockProps]
}

const Block = ({ Tag, blockProps, children, ...other }) => {
    const ref = useRef()
    const visible = useRef()
    const unmounted = useUnmounted()
    const { className, ...otherProps } = blockProps || {}
    const classes = className
    const onIntersect = useCallback(
        entry => {
            if (unmounted.current || visible.current || !entry.isIntersecting) return
            visible.current = true
            ref.current.classList.add("visible")
        },
        [unmounted, ref, visible]
    )
    useIntersection(ref, onIntersect)
    return (
        <Tag ref={ref} className={classes} {...otherProps} {...other}>
            {children}
        </Tag>
    )
}
const renderBlock = (block, i, props, info) => {
    const [Tag, blockProps] = getBlockTag(block)

    if (Tag)
        return (
            <Block Tag={Tag} key={i} {...blockProps}>
                {props.iconBefore && props.iconBefore[Tag] ? (
                    <FaIcon {...props.iconBefore[Tag]} />
                ) : null}
                {block.nodes.map((node, j) => renderNode(node, j, props, info))}
                {props.iconAfter && props.iconAfter[Tag] ? (
                    <FaIcon {...props.iconAfter[Tag]} />
                ) : null}
            </Block>
        )

    switch (block.type) {
        case "iframe": {
            const title = block.data && block.data.title ? block.data.title : ""
            return <iframe key={i} title={title} {...block.data}></iframe>
        }
        case "image": {
            const images = block.data.images
            //console.log(images)

            /*if (images.length === 1)
                return (
                    <Block
                        key={i}
                        Tag={LazyImage}
                        src={images[0]}
                        imageStyle="img"
                        {...blockProps}
                    />
                )*/

            return (
                <Block Tag="div" key={i} {...blockProps}>
                    {images.map((image, j) => (
                        <LazyImage key={j} src={image} imageStyle="img" />
                    ))}
                </Block>
            )
        }
        case "list-item-child":
            return (
                <React.Fragment key={i}>
                    {block.nodes.map((node, j) => renderNode(node, j, props, info))}
                </React.Fragment>
            )
        default:
            console.log("cannot render block", block)
            return null
    }
}
const renderInline = (inline, i, props, info) => {
    switch (inline.type) {
        case "link":
            return (
                <Link key={i} to={inline.data.href}>
                    {props.iconBefore && props.iconBefore.a ? (
                        <FaIcon {...props.iconBefore.a} />
                    ) : null}
                    {inline.nodes.map((node, i) => renderNode(node, i, props, info))}
                    {props.iconAfter && props.iconAfter.a ? (
                        <FaIcon {...props.iconAfter.a} />
                    ) : null}
                </Link>
            )
        case "attach":
            return (
                <React.Fragment key={i}>
                    <PullQuote
                        info={{ entity: info.entity, user: info.user, language: info.language }}
                        attachment={inline.data.attachment}
                    />
                    <span key={i} className="with-attachment">
                        {inline.nodes.map((node, i) => renderNode(node, i, props, info))}
                    </span>
                </React.Fragment>
            )
        default:
            console.log("cannot render inline", inline)
            return null
    }
}

const renderMark = (children, mark) => {
    const Tag = MARK_TAGS[mark.type]
    if (Tag) return <Tag>{children}</Tag>

    if (mark.type === "inlineStyle") return <span style={mark.data}>{children}</span>

    return null
}
const renderLeafText = text => {
    //console.log(text, text.length, el)
    if (!text) return null
    const chunks = text.split("\n")
    return chunks.map((chunk, i) => (
        <React.Fragment key={i}>
            {chunk}
            {i < chunks.length - 1 && <br />}
        </React.Fragment>
    ))
}

const renderLeaf = (leaf, props) => {
    if (leaf.marks)
        return leaf.marks.reduce(
            (children, mark) => renderMark(children, mark, props),
            renderLeafText(leaf.text)
        )
    return renderLeafText(leaf.text)
}
const renderText = (node, i, props) => (
    <React.Fragment key={i}>
        {node.leaves
            ? node.leaves.map((leaf, j) => (
                  <React.Fragment key={j}>{renderLeaf(leaf, props)}</React.Fragment>
              ))
            : renderLeaf(node, props)}
    </React.Fragment>
)

const renderNode = (node, i, props, info) => {
    switch (node.object) {
        case "block":
            return renderBlock(node, i, props, info)
        case "inline":
            return renderInline(node, i, props, info)
        case "text":
            return renderText(node, i, props)
        default:
            return null
    }
}
const getSimpleText = (acc, node, i) => {
    switch (node.object) {
        case "block":
            return node.nodes.reduce(getSimpleText, acc)
        case "inline":
            return node.nodes.reduce(getSimpleText, acc)
        case "text":
            return [...acc, node.text]
        default:
            return acc
    }
}
const getTrim = (value, trim) => {
    const text = value.document.nodes
        .reduce(getSimpleText, [])
        .join(" ")
        .replace("&nbsp;", " ")
    return text.length > trim ? text.substring(0, trim) + "..." : text
}
const RawSlate = ({ domRef, field, value, info, ...other }) => {
    //console.log('render HTML', field, 'in', entity.type)
    if (!value || !value.document) return null
    if (info.fieldInfo.trim || info.displayInfo.trim) {
        //state.trim = info.fieldInfo.trim
        //state.n = 0
        return getTrim(value, info.fieldInfo.trim || info.displayInfo.trim)
    }
    return value.document.nodes.map((node, i) => renderNode(node, i, other, info))
}
export default React.memo(RawSlate)
