import axios from "axios"
import React from "react"
import { useQuery, Link } from "lib"

//const normalize = s => s.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")

const useSolr = (input) => {
    const [state, setState] = React.useState({input,q:null, numFound: 0})
    React.useEffect(() => {
        const search = async () => {
            const res = await axios.post("search", {
                query: `${input}~1`,
                fields: ["id"],
                sort: "date desc, score desc",
                params: {
                    hl: "on",
                    "hl.fl": "title"
                }
            })
            const {highlighting, responseHeader, response, error} = res.data
            console.log(response, responseHeader)
            if(error) {
                console.log(error)
                return
            }
            const {numFound, docs} = response
            //console.log(docs)
            const q = numFound>0?{
                collection: "node",
                query: {_id: {$in: docs.map(doc => doc.id)}},
                sort: {date:-1, _id:-1}
            }:null
            //console.log("RET", numFound, start, docs)
            setState({q, input, numFound, highlighting})
        }
        if(input)
            search(input)
    }, [input])
    const [results] = useQuery(state.q)
    //console.log(state, results, status, n)
    return [results, state.numFound, state.highlighting]
}
/*
const useSearch = input => {
    return useSolr(input)
    const [q, inputNormal] = React.useMemo(() => {
        if(!input) return [null, input]
        const input1 = normalize(input)
        //console.log(input, input1)
        return [{
            collection: "node",
            query: {
                type: "document",
                $text: {
                    $search: input1
                }
                /*$or: [
                    {title: {$regex: `.*${input.toLowerCase()}.*`, $options: "i"}},
                    {subtitle: {$regex: `.*${input.toLowerCase()}.*`, $options: "i"}}
                ] * /
            },
            projection: {title:1, date:1, path:1},
            sort: {date:-1},
            limit: 5
        }, input1]
    }, [input])
    const [results, , n] = useQuery(q)
    //console.log(results, status, n)
return [results, n, inputNormal]

}

const getHighlights = (text, highlight) => {
    const toks = highlight.split(" ").map(t => t.trim()).filter(t => t.length>2)
    let hl = []
    const ntext = normalize(text)
    let m
    for(const tok of toks) {
        const re = new RegExp(tok, "ig")
        while ((m = re.exec(ntext))!==null)
            hl.push([m.index, m[0].length])
    }
    hl = hl.sort((a,b) => {
        if(a[0]<b[0]) return -1
        if(a[0]===b[0]) return 0
        return 1
    })
    return hl
}

const Highlight = ({text, highlight}) => {
    const hl = getHighlights(text, highlight)
    let i = 0
    let ret = []
    for(const h of hl) {
        if(i!==h[0])
            ret.push(text.slice(i, h[0]))
        ret.push(<span key={i} className="highlight">{text.slice(h[0], h[0]+h[1])}</span>)
        i = h[0]+h[1]
    }
    if(i<text.length) ret.push(text.slice(i))
    return ret
}
//<Highlight text={hldoc.title} highlight={inputNormal}/>
*/
const SearchResults = ({input, onReset}) => {
    const ret = useSolr(input)
    //console.log(ret)
    const [results, n, hl] = ret
    const handleActivate = React.useCallback(() => {
        onReset()
        return true
    }, [onReset])
    if(!results || n===0) return null
    
    return (
        <div className="search-results">
            {results.map((doc, i) => (
                <div key={i} className="result">
                    <Link to={doc.path} onActivate={handleActivate}>
                    <span dangerouslySetInnerHTML={{__html:hl?.[doc._id]?.title}}/></Link>
                    </div>
            ))}
            <div className="search-total">{n>1 ? `Rezultate: 1 - ${results.length} din ${n}` : "1 rezultat"} {n>5 && <Link to={`/cautare?search=${encodeURIComponent(input)}`} onActivate={handleActivate}>Vezi toate rezultatele...</Link>}</div>
        </div>
    )
}

const useSearchInput = () => {
    const [input, setInput] = React.useState('')
    const handleChange = React.useCallback(e => {
        const input = e.target.value
        setInput(input)
    },[])
    return {value: input, onChange:handleChange}
}

const SearchInput = ({value, onChange}) => {
    const [focus, setFocus] = React.useState()
    const handleFocus = React.useCallback(() => {
        setFocus(true)
    }, [])
    const handleBlur = React.useCallback(() => {
        setFocus(false)
    }, [])
    const args = {}
    if(!value) args["is-empty"] = ""
    if(focus) args["is-focus"] = ""
    return (
        <div className="search-form-field" {...args}>
            <label htmlFor="search">Caută documente...</label>
            <input id="search" type="search" name="search" value={value} onChange={onChange} onFocus={handleFocus} onBlur={handleBlur}/>
        </div>
    )
}

const SearchBar = () => {
    const args = useSearchInput()
    const handleReset = React.useCallback(() => {
        args.onChange({target: {value: ""}})
    }, [args])
    return (
        <div id="search-bar">
            <SearchInput {...args}/>
            <SearchResults input={args.value} onReset={handleReset}/>
        </div>
    )
}
export default SearchBar
