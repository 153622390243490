//import React from "react"
import { Entity } from "lib"
import { documentStateTypes } from "./config"
const _handleChangeState = (entity, state) => () => {
    const e = Entity.set(entity, "state", state.val)
    Entity.save(e)
}
export const nodeTypes = {
    homepage: { label: "Acasă", _noCreate: true },
    panel: {
        label: "Pagină",
        info: "Pagină",
        searchField: "title",
        fields: {
            title: { fullWidth: true },
            summary: { fullWidth: true, multiline: true, rows: 5 },
        },
    },
    page: {
        label: "Pagină simplă",
        info: "Pagină generică",
        searchField: "title",

        //_noCreate: true,
        fields: {
            title: { fullWidth: true },
            summary: { fullWidth: true, multiline: true, rows: 5 },
        },
        presave: entity => {
            if ((!entity.cat || !entity.cat.ref || !entity.cat.hasperson) && entity.date) {
                entity.year = entity.date.getFullYear()
            }
            return entity
        },
    },

    document: {
        label: "Document",
        info: "Document",
        searchField: "title",
        //_noCreate: true
        fields: {
            title: { fullWidth: true },
            summary: { fullWidth: true, multiline: true, rows: 5 },
        },
        presave: entity => {
            if (entity.date) entity.year = entity.date.getFullYear()
            return entity
        },
        contextMenu: (user, entity) =>
            entity.kind === "16"
                ? documentStateTypes.map(state => ({
                      label: state.label,
                      radio: true,
                      checked: entity.state === state.val,
                      handler: _handleChangeState(entity, state),
                  }))
                : [],
    },
    docList: {
        label: "Lista documente",
        searchField: "title",
        fields: {
            title: { fullWidth: true },
            summary: { fullWidth: true, multiline: true, rows: 5 },
        },
    },
    article: {
        label: "Articol",
        searchField: "title",

        fields: {
            title: { fullWidth: true },
            summary: { fullWidth: true, multiline: true, rows: 5 },
        },
        presave: entity => {
            if ((!entity.cat || !entity.cat.ref || !entity.cat.hasperson) && entity.date)
                entity.year = entity.date.getFullYear()
            return entity
        },
    },
    folder: {
        label: "Dosar",
        info: "Dosar",
        searchField: "title",
        //_noCreate: true,
        fields: {
            title: { fullWidth: true },
            summary: { fullWidth: true, multiline: true, rows: 5 },
        },
    },
    NotFound: {
        label: "Pagină inexistentă",
        _noCreate: true,
    },
}

export const entityTypes = {
    menu: {
        label: "Meniu",
        collection: "menu",
        getLabel: e => e.label,
        _noCreate: true,
    },
    category: {
        label: "Categorie",
        collection: "cat",
        searchField: "title",
    },

    person: {
        label: "Persoană",
        getLabel: entity => `${entity.firstName} ${entity.lastName}`,
        refOptions: {
            searchField: ["firstName", "lastName"],
        },
    },

    footer: {
        label: "Footer",
        collection: "site",
    },
    header: {
        label: "Header",
        collection: "site",
        _noCreate: true,
    },
    antet: {
        label: "Antet",
        collection: "antet",
        searchField: "title",
    },
    bg: {
        label: "Fond",
        collection: "bg",
        getLabel: e => "Fond",
    },
}
