import React from "react"
import { Link } from "lib"

const ReadMore = ({ entity }) => (
    <Link to={entity.path} className="read-more">
        [Citește mai departe]
    </Link>
)
export const display = {
    default: {
        _seo: false,
        title: {
            tag: "h1",
            region: "hero",
        },
        subtitle: { region: "hero" },
        date: { region: "hero" },
        nr: false,
        thumb: false,
        summary: false,
        promoted: false,
        cat: false,
        person: false,
        state: false,
        year: false,
        showTime: false,
        position: false,
        display: false,
        nofilter: false,
        header: { region: "header", bg: true, proportional: true },
        docList: {
            cat: true,
        },
    },
    teaser: {
        title: { link: true },
        subtitle: true,
        summary: true,
        thumb: true,
        thumb_front: true,
        date: true,
        firstName: true,
        lastName: true,
        year: true,
    },
    teaser2: {
        title: { tag: "h3", link: true },
        subtitle: true,
        body: { trim: 150, after: ReadMore },
        thumb_front: true,
    },
    title: {
        title: { tag: "div", link: true },
        subtitle: true,
    },
    teaserfront: {
        title: { tag: "h3", link: true },

        body: { trim: 150, after: ReadMore },
        thumb_front: true,
        _layout: ["title", ["content", "thumb_front", "body"]],
    },
    pageteaser: {
        thumb: true,
        thumb_front: true,
        title: { tag: "h3", link: true },
        body: { trim: 150, after: ReadMore },
        _layout: ["thumb", "title", "body"],
    },
    pageteaser2: {
        thumb: true,
        thumb_front: true,
        title: { tag: "h3", link: true },
        body: { trim: 150, after: ReadMore },
        _layout: ["thumb", "title", "body"],
    },
}
