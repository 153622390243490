import React from "react"
import { Link } from "lib"
import { useMenuLeft } from "./MenuLeft"

const findRecursive = (menu, searchLocation) => {
    const { p, title, items } = menu
    if (p === searchLocation) {
        return [{ p, title }]
    }
    if (!items) return null

    const section = [{ p, title }]
    for (let i in items) {
        let subsection = findRecursive(items[i], searchLocation)
        if (subsection) return section.concat(subsection)
    }
    return null
}

const useSections = entity => {
    const menu = useMenuLeft()
    if (!entity || !menu) return null
    const location = entity.path
    if (!location) return null
    let toks = location.split("/")
    toks.pop()
    const searchLocation = toks.join("/")

    let sections = null
    for (let i in menu) {
        sections = findRecursive(menu[i], searchLocation)
        if (sections) return sections
    }
    return null
}

const Breadcrumb = ({ entity }) => {
    const sections = useSections(entity)

    return (
        <div className="breadcrumb">
            {sections &&
                sections.map((section, i) => (
                    <div key={i} className="item">
                        <Link to={section.p}>{section.title}</Link>
                    </div>
                ))}
        </div>
    )
}
export default React.memo(Breadcrumb)
