import React from "react"
import { AuthButton, EntityView, useQuery } from "lib"
import MainMenu, { useMainMenu } from "../header/MainMenu"

const footerQuery = {
    collection: "site",
    query: {
        type: "footer",
    },
}
const useFooter = () => {
    const [data] = useQuery(footerQuery)
    if (!data || data.length === 0) return null
    return data[0]
}
const Footer = ({ node, language }) => {
    const footer = useFooter()
    const menu = useMainMenu()

    return (
        <footer id="footer">
            <MainMenu id="footer-main-menu" menu={menu} language={language} />
            <div className="footer-in">{footer && <EntityView entity={footer} dynamic />}</div>

            <div className="copyright">
                Copyright © 2000-{new Date().getFullYear()} Primăria comunei Feldru
            </div>

            <AuthButton />
        </footer>
    )
}
export default React.memo(Footer)
