import React, { useRef, useEffect, useCallback } from "react"
import Header from "../header/Header"
import Footer from "./Footer"
import Node from "lib/app/node/Node"
//import Antet from "./Antet"
import Bg from "./Bg"
import Accessibility from "../header/Accessibility"
//import MenuLeft from "./MenuLeft"
//import TooltipManager from "./layout/TooltipManager"
//const Admin = React.lazy(() => import("./layout/Admin"))

const useIsScrolledToTop = () => {
    const notTop = useRef(false)
    const scroll = useRef(0)
    const direction = useRef(false)
    const delta = useRef(0)

    const running = useRef()
    const update = useCallback(() => {
        let root
        if (scroll.current > 100) {
            if (!notTop.current) {
                notTop.current = true
                if (!root) root = document.getElementById("root")
                root.classList.add("not-top")
            }
        } else {
            if (notTop.current) {
                notTop.current = false
                if (!root) root = document.getElementById("root")
                root.classList.remove("not-top")
            }
        }
        if (
            (delta.current >= 0 && direction.current) ||
            (delta.current < 0 && !direction.current)
        ) {
            running.current = false
            return
        }
        if (delta.current >= 0) {
            direction.current = true
            if (!root) root = document.getElementById("root")
            root.classList.remove("up")
            root.classList.add("down")
        } else {
            direction.current = false
            if (!root) root = document.getElementById("root")
            root.classList.remove("down")
            root.classList.add("up")
        }
        running.current = false
    }, [scroll, notTop, delta, running, direction])
    const handleScroll = useCallback(() => {
        const oldScroll = scroll.current
        scroll.current = window.scrollY
        delta.current = scroll.current - oldScroll

        if (!running.current) {
            running.current = true
            window.requestAnimationFrame(update)
            //setTimeout(update, 500)
        }
    }, [update, scroll, delta, running])

    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll, { passive: true })
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [handleScroll])
}
const useIsHomepage = node => {
    const nodeRef = useRef()
    useEffect(() => {
        if (!node || nodeRef.current === node.type) return
        nodeRef.current = node.type

        const root = document.getElementById("root")
        if (node.type === "homepage") {
            root.classList.add("homepage")
        } else {
            root.classList.remove("homepage")
        }
    }, [node])
}
const Layout = ({ location, search, language, node, first }) => {
    useIsScrolledToTop()
    useIsHomepage(node)
    //console.log(node)
    return (
        <>
            <Bg />
            <Accessibility node={node}>
                <Header node={node} first={first} language={language} />
                <div region-main="" node-type={node ? node.type : "none"}>
                    <Node
                        location={location}
                        search={search}
                        language={language}
                        node={node}
                        first={first}
                    />
                </div>
                <Footer node={node} language={language} />
                <div id="modal-container" />
            </Accessibility>
        </>
    )
}

export default React.memo(Layout)
