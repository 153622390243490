import React from "react"
import Entity from "../../../entity"

const FormFieldText = ({ domRef, fieldValue, dispatch, info, value, ...props }) => {
    const handleChange = e => {
        //console.log(info.fieldInfo, value)
        dispatch({
            type: "change",
            field: info.field,
            label: value.label,
            fieldType: value.fieldType,
            value: e.currentTarget.value,
        })
    }

    return (
        <div ref={domRef} {...props}>
            <input
                name={info.field}
                type="text"
                value={fieldValue || ""}
                placeholder={Entity.get(value, "label")}
                onChange={handleChange}
            />
        </div>
    )
}
export default FormFieldText
