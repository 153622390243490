export * from "./util"
export { default as requestIdleCallback } from "./idle"
export const flatten = a => {
    if (typeof window !== "undefined" && a.flat) return a.flat()
    return a.reduce((acc, i) => acc.concat(Array.isArray(i) ? i : [i]), [])
}
export const hashstr = s => {
    let hash = 0
    if (s.length === 0) return hash
    for (let i = 0; i < s.length; i++) {
        let char = s.charCodeAt(i)
        hash = (hash << 5) - hash + char
        hash = hash & hash // Convert to 32bit integer
    }
    return hash
}
