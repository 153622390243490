import React, { Suspense, useRef, useContext, useCallback } from "react"
import { AuthContext } from "../auth"
import { useUnmounted, useSubscriptionProvider } from "../hooks"
import { useIntersection } from "../intersection"
import Entity from "../entity"
import Field from "./Field"
import { renderLayout } from "./Region"
const FieldManager = React.lazy(() =>
    import("admin").then(module => ({ default: module.FieldManager }))
)

const EntityView = props => {
    const { info, entity, visibility, dynamic, noadmin } = props

    const domRef = useRef()
    const unmounted = useUnmounted()
    const visible = useRef()
    const { user } = useContext(AuthContext)
    const setFieldAdmin = useSubscriptionProvider("fieldAdmin")
    const display = props.display || (info ? info.display : undefined)
    const entityInfo = Entity.getTypeInfo(entity)
    //console.log(entity, entityInfo)
    const newInfo = {
        entity,
        entityInfo,
        user,
        display,
        language: info ? info.language : undefined,
    }
    //console.log(props, data, user)

    const parents = useRef()
    const lineageId = useRef()
    const setupLineage = () => {
        parents.current = [
            ...(info && info.parents && info.parents.current ? info.parents.current : []),
            { domRef: domRef, ...props },
        ]
        lineageId.current = ""
        parents.current.forEach(item => {
            const { entity, field } = item
            lineageId.current += `${entity ? Entity.getId(entity) : 0} - ${field}`
        })
    }
    const mouseOver = useRef(false)
    const onMouseOver = useCallback(() => {
        if (noadmin || !user) return
        if (mouseOver.current) return
        //console.log("mouse over", parents.current)
        mouseOver.current = true
        setFieldAdmin({ mouse: "enter", fieldLine: parents.current })
    }, [user, noadmin, setFieldAdmin])

    const onMouseLeave = useCallback(() => {
        if (noadmin || !user) return
        //console.log("mouse leave", parents.current)
        mouseOver.current = false
        setFieldAdmin({ mouse: "leave", fieldLine: parents.current })
    }, [user, noadmin, setFieldAdmin])

    const onIntersect = useCallback(
        entry => {
            if (unmounted.current || visible.current || !entry.isIntersecting) return
            visible.current = true
            domRef.current.classList.add("visible")
        },
        [unmounted, visible, domRef]
    )
    useIntersection(visibility ? domRef : null, onIntersect)

    if (!entity) return null
    const wrapperProps = {}
    if (user) {
        /* && !parents.current)*/
        setupLineage()
        newInfo.parents = parents
        wrapperProps.onMouseOver = onMouseOver
        wrapperProps.onMouseLeave = onMouseLeave
    }
    const classes = `entity entity-type-${entity.type} ${props.className || ""}`

    const Tag = props.tag || "div"

    if (!props.children) {
        //console.log(o)
        const displayInfo = Entity.getDisplayInfo(entity, display)
        const pseudoFields = ["_layout", "_footer"]
        let o = Object.keys(displayInfo).filter(f => !pseudoFields.includes(f))
        //console.log(entity, display, displayInfo)
        /*
        if (entityInfo && entityInfo.display && entityInfo.display[display]) {
            const keys = Object.keys(entityInfo.display[display])
            o = o.filter(field => keys.includes(field))
        }*/
        //console.log(displayInfo, display)
        return (
            <Tag ref={domRef} className={classes} {...wrapperProps}>
                {displayInfo._layout
                    ? renderLayout(newInfo, displayInfo._layout, o)
                    : o.map((f, i) => <Field key={i} field={f} info={newInfo} />)}
                {entityInfo &&
                entityInfo.display &&
                entityInfo.display[display] &&
                entityInfo.display[display]._footer
                    ? entityInfo.display[display]._footer(entity)
                    : null}
                {user && dynamic && (
                    <Suspense fallback={null}>
                        <FieldManager user={user} entity={entity} />
                    </Suspense>
                )}
            </Tag>
        )
    }
    return (
        <Tag ref={domRef} className={classes} {...wrapperProps}>
            {props.children(newInfo)}
        </Tag>
    )
}
export default EntityView
