import React from "react"
import { Field } from "lib"

const Minicard = ({ domRef, info, value, ...props }) => {
    if (!value) return null
    //console.log(value, props)
    return (
        <>
            <Field info={info} field="title" />
            <Field info={info} field="icon" />
            <div className="mc">
                <Field info={info} field="name" />
                <Field info={info} field="phone" />
                <Field info={info} field="mail" />
            </div>
        </>
    )
}
export default React.memo(Minicard)
