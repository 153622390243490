import { useState, useCallback } from "react"

export default function useLocalStorage(key, initialValue) {
    // The initialValue arg is only used if there is nothing in localStorage ...
    // ... otherwise we use the value in localStorage so state persist through a page refresh.
    // We pass a function to useState so localStorage lookup only happens once.
    // We wrap in try/catch in case localStorage is unavailable
    const [item, setInnerValue] = useState(() => {
        if (!key || typeof window==="undefined") return initialValue
        try {
            return window.localStorage.getItem(key)
                ? JSON.parse(window.localStorage.getItem(key))
                : initialValue
        } catch (error) {
            // Return default value if JSON parsing fails
            //console.log(error)
            return initialValue
        }
    })

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = useCallback(value => {
        setInnerValue(value)
        if (!key||typeof window==="undefined") return
        localStorage.setItem(key, JSON.stringify(value))
    }, [key])
    /*
  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(item));
  }, [item]);
*/
    return [item, setValue]
}
