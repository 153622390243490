import bugsnag from "@bugsnag/js"
import bugsnagReact from "@bugsnag/plugin-react"
import React from "react"
import ReactDOM from "react-dom"
import * as serviceWorker from "serviceWorker"
import { LanguageManager, Auth } from "lib"
import Location from "lib/app/Location"
import { HelmetProvider } from "react-helmet-async"

const bugsnagClient = bugsnag("fa592035e267aadaa92672c4deffb304")
bugsnagClient.use(bugsnagReact, React)
const ErrorBoundary = bugsnagClient.getPlugin("react")
if (window.initialStore) window.isHydrating = true
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true

ReactDOM.hydrate(
    <ErrorBoundary>
        <HelmetProvider>
            <LanguageManager>
                <Auth>
                    <Location />
                </Auth>
            </LanguageManager>
        </HelmetProvider>
    </ErrorBoundary>,
    document.getElementById("root"),
    () => {
        window.isHydrating = false
        delete window.initialStore
    }
)

serviceWorker.unregister()
