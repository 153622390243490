import React from "react"
import { C, useHistory, useQuery, Link, FaIcon } from "lib"

const qMenu = {
    collection: "menu",
    query: {
        label: "Meniu stânga",
    },
}
export const useMenuLeft = () => {
    const [menus] = useQuery(qMenu)
    return menus?.[0]?.items
}
const MenuItem = ({ item, path, level }) => {
    const [open, setOpen] = React.useState(() => path.indexOf(item.p) === 0)
    const [selected, setSelected] = React.useState(() => path.indexOf(item.p) === 0)
    const hasSubmenu = item.items && item.items.length > 0
    const handleActivate = React.useCallback(
        e => {
            if (!hasSubmenu) return true
            if (selected && path === item.p) {
                setOpen(open => !open)
                return false
            }
            if (!open) {
                setOpen(true)
                return false
            }
            return true
        },
        [hasSubmenu, open, selected, path, item.p]
    )
    const toggleOpen = React.useCallback(e => {
        setOpen(open => !open)
    }, [])
    const onHover = React.useCallback(() => {
        const a11y = item?._e?.title?.a11y
        if (!a11y) return
        document.getElementById("a11y-audio").src = `${C.BASE}/audio/${a11y}.mp3`
    }, [item])
    React.useEffect(() => {
        if (path && path.indexOf(item.p) === 0) {
            setOpen(true)
            setSelected(true)
        } else {
            setOpen(false)
            setSelected(false)
        }
    }, [path, item.p])
    let classes = hasSubmenu ? (open ? "has-submenu open" : "has-submenu closed") : ""
    if (selected) classes += " selected"
    return (
        <li className={classes}>
            {level > 0 && hasSubmenu && (
                <FaIcon icon="faAngleRight" size={16} onClick={toggleOpen} />
            )}
            <Link to={item.p} onActivate={handleActivate} onMouseOver={onHover}>
                {item.title}
            </Link>
            {item.items && (
                <ul className="menu">
                    {item.items.map((item, i) => (
                        <MenuItem item={item} path={path} key={i} level={level + 1} />
                    ))}
                </ul>
            )}
        </li>
    )
}

const MenuLeft = ({ node }) => {
    const menu = useMenuLeft()
    const { location } = useHistory()
    const path = location ? location.pathname : null

    return (
        <div className="menu-left">
            <ul className="menu">
                {menu?.map((item, i) => (
                    <MenuItem item={item} path={path} key={i} level={0} />
                ))}
            </ul>
        </div>
    )
}

export default MenuLeft
