import React from "react"
import { Region } from "lib"
import MenuLeft from "./MenuLeft"
import Breadcrumb from "./Breadcrumb"

export const DefaultLayout = ({ entity }) => (
    <>
        <audio id="a11y-audio" autoPlay />
        <Region region="hero" entity={entity}>
            <Breadcrumb entity={entity} />
        </Region>
        <div region-node-main="">
            <MenuLeft node={entity} />
            <Region entity={entity} dynamic />
        </div>
    </>
)

const HomeLayout = ({ entity }) => (
    <div region-node-main="">
        <audio id="a11y-audio" autoPlay />
        <MenuLeft node={entity} />
        <Region entity={entity} dynamic />
    </div>
)
export const nodeLayout = {
    homepage: HomeLayout,
}
