import React from "react"
import { FaIcon, useLocalStorage } from "lib"
import { AccessibilityContext } from "lib/hooks/useAccessibility"

const fontClasses = {
    1: "a11y-font-big",
    2: "a11y-font-bigger",
    3: "a11y-font-biggest",
}
const Accessibility = ({ node, children }) => {
    const [open, setOpen] = React.useState()
    const [a11y, setA11y] = useLocalStorage("a11y", {})
    const toggleOpen = React.useCallback(() => {
        setOpen(open => !open)
    }, [])
    const increaseFontSize = React.useCallback(() => {
        if ((a11y.font ?? 0) >= 3) return
        setA11y({ ...a11y, font: (a11y?.font ?? 0) + 1 })
    }, [a11y, setA11y])
    const decreaseFontSize = React.useCallback(() => {
        if ((a11y.font ?? 0) <= 0) return
        setA11y({ ...a11y, font: a11y.font - 1 })
    }, [a11y, setA11y])
    const bw = React.useCallback(() => {
        const bw = !(a11y.bw ?? false)
        setA11y({ ...a11y, bw })
    }, [a11y, setA11y])
    const audio = React.useCallback(() => {
        const audio = !(a11y.audio ?? false)
        setA11y({ ...a11y, audio })
    }, [a11y, setA11y])
    const invert = React.useCallback(() => {
        const invert = !(a11y.invert ?? false)
        setA11y({ ...a11y, invert })
    }, [a11y, setA11y])
    /*const toggleReader = React.useCallback(() => {
        const reader = !(a11y.reader ?? false)
        const synth = window.speechSynthesis
        const listVoices = () => {
            const voices = synth.getVoices()
            console.log(voices.length)
            for (const v of voices) {
                console.log(v.name, v.lang)
            }
        }
        console.log(synth)
        synth.onvoiceschanged = () => {
            listVoices()
        }
        const msg = new SpeechSynthesisUtterance("primaria comunei feldru")
        msg.lang = "ro-RO"
        const voices = synth.getVoices()
        console.log(voices.length)
        for (const v of voices) {
            console.log(v.name, v.lang)
        }
        synth.speak(msg)
        setA11y({ ...a11y, reader })
    }, [a11y, setA11y])*/
    const revert = React.useCallback(() => {
        setA11y({})
    }, [setA11y])
    React.useEffect(() => {
        const a11yAudio = document.getElementById("a11y-audio")
        if (!a11yAudio) return
        a11yAudio.pause()
    }, [node])
    React.useEffect(() => {
        const classes = []
        if ((a11y.font ?? 0) > 0) classes.push(fontClasses[a11y.font])
        if (a11y.invert) classes.push("a11y-invert")
        if (a11y.bw) classes.push("a11y-bw")
        const values = Array.from(document.body.classList.values())
        for (const c of values) {
            if (c.indexOf("a11y-") >= 0) document.body.classList.remove(c)
        }
        if (classes.length > 0) document.body.classList.add(...classes)
        if (!a11y.audio) {
            const a11yAudio = document.getElementById("a11y-audio")
            if (!a11yAudio) return
            a11yAudio.pause()
        }
    }, [a11y])
    //console.log(a11y.audio)
    return (
        <AccessibilityContext.Provider value={a11y}>
            <div className={`a11y ${open ? "open" : ""}`}>
                <div className="a11y-button" title="" onClick={toggleOpen}>
                    <FaIcon icon="action/accessibility" />
                </div>
                <div className="a11y-menu">
                    <div className="a11y-button" title="Mărire scris" onClick={increaseFontSize}>
                        <FaIcon icon="action/zoom_in" />
                        Mărire scris
                    </div>
                    <div className="a11y-button" title="Micșorare scris" onClick={decreaseFontSize}>
                        <FaIcon icon="action/zoom_out" />
                        Micșorare scris
                    </div>
                    <div className="a11y-button" title="Alb/Negru" onClick={bw}>
                        <FaIcon icon="image/filter_b_and_w" />
                        Alb/Negru
                    </div>
                    <div className="a11y-button" title="Inversează culorile" onClick={invert}>
                        <FaIcon icon="action/invert_colors" />
                        Inversează culorile
                    </div>
                    <div className="a11y-button" title="Valori implicite" onClick={revert}>
                        <FaIcon icon="content/undo" /> Valori implicite
                    </div>
                    <div className="a11y-button" title="Audio" onClick={audio}>
                        <FaIcon icon={a11y.audio ? "av/volume_up" : "av/volume_off"} /> Audio
                    </div>
                </div>
            </div>
            {children}
        </AccessibilityContext.Provider>
    )
}

export default Accessibility
