import axios from "axios"
import React from "react"
import {
    useHistory,
    //Entity,
    Pager,
    pagerToFirstPage,
    EntityView,
    FaIcon,
    useQuery,
    //flatten,
    //Link
} from "lib"

const useSolr = (data) => {
    const { input, years, page, pageSize } = data
    //console.log(years)
    const [state, setState] = React.useState({input, q:null, numFound: 0})
    React.useEffect(() => {
        const search = async (input, offset, years) => {
            //console.log(input, offset)
            let query = `title:${input}~1`
            if(years.length>0) {
                query += " AND (" + years.map(y => `year:${y}`).join(" OR ") + ")"
            }
            const res = await axios.post("search", {
                query,
                fields: ["id"],
                sort: "date desc, score desc",
                offset,
            })
            const {response, error} = res.data
            //console.log(res.data, response, responseHeader)
            if(error) {
                console.log(error)
                return
            }
            const {numFound, docs} = response
            //console.log(docs)
            const q = numFound>0?{
                collection: "node",
                query: {_id: {$in: docs.map(doc => doc.id)}},
                sort: {date:-1, _id:-1},
                projection: {
                    title: 1,
                    subtitle: 1,
                    body: 1,
                    thumb: 1,
                    thumb_front: 1,
                    type: 1,
                    path: 1,
                    date: 1,
                    showTime: 1,
                    state: 1,
                    docs: 1,
                    cat: 1,
                    _e: 1,
                    //...(cat.hasperson ? { person: 1, year: 1 } : {}),
                },
            }:null
            //console.log("RET", numFound, start, docs)
            setState({q, input, numFound})
        }
        if(input)
            search(input, (page-1) * pageSize, years)
        else
            setState({q:null, input, numFound:0})
    }, [input, page, pageSize, years])
    const [results, status] = useQuery(state.q)
    //console.log(state, results, status, n)
    return [results, status, state.numFound]
}

const useSolrFacets = (input) => {
    const [state, setState] = React.useState({input, facets:null})
    React.useEffect(() => {
        const search = async (input) => {
            const query = `${input}~1`
            const res = await axios.post("search", {
                query,
                fields: ["id"],
                    facet: {
                        year: {
                            type:"terms",
                            field: "year",
                            sort: {index: "desc"}
                        },
                    }
            })
            const {facets, error} = res.data
            //console.log(res.data)
            if(error) {
                console.log(error)
                return
            }
            setState({input, facets})
        }
        if(input)
            search(input)
    }, [input])
    return state.facets
}

//import { useQuery, Link } from "lib"
//import useSearch from "search/useSearch"
const normalize = s => s?s.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""):""
/*const useSearch = input => {
    const [q, inputNormal] = React.useMemo(() => {
        if(!input) return [null, input]
        const input1 = normalize(input)
        console.log(input, input1)
        return [{
            collection: "node",
            query: {
                type: "document",
                $text: {
                    $search: input1
                }
            },
            projection: {title:1, date:1, path:1},
            sort: {date:-1},
            limit: 5
        }, input1]
    }, [input])
    const [results, status, n] = useQuery(q)
    console.log(results, status, n)
    return [results, n, inputNormal]
}

const getHighlights = (text, highlight) => {
    const toks = highlight.split(" ").map(t => t.trim()).filter(t => t.length>2)
    let hl = []
    const ntext = normalize(text)
    let m
    for(const tok of toks) {
        const re = new RegExp(tok, "ig")
        while ((m = re.exec(ntext))!==null)
            hl.push([m.index, m[0].length])
    }
    hl = hl.sort((a,b) => {
        if(a[0]<b[0]) return -1
        if(a[0]===b[0]) return 0
        return 1
    })
    return hl
}

const Highlight = ({text, highlight}) => {
    const hl = getHighlights(text, highlight)
    let i = 0
    let ret = []
    for(const h of hl) {
        if(i!==h[0])
            ret.push(text.slice(i, h[0]))
        ret.push(<span className="highlight">{text.slice(h[0], h[0]+h[1])}</span>)
        i = h[0]+h[1]
    }
    if(i<text.length) ret.push(text.slice(i))
    return ret
}

const SearchResults = ({input}) => {
    const [results, n, inputNormal] = useSearch(input)
    if(!results || n===0) return null
    
    return (
        <div className="sp-search-results">
            {results.map((doc, i) => (
                <div key={i} className="result">
                    <Link to={doc.path}><Highlight text={doc.title} highlight={inputNormal}/></Link>
                    </div>
            ))}
            <div className="search-total">{n>1 ? `Rezultate: 1 - ${results.length} din ${n}` : "1 rezultat"} </div>
        </div>
    )
}

const useSearchInput = () => {
    const [input, setInput] = React.useState('')
    const handleChange = React.useCallback(e => {
        const input = e.target.value
        setInput(input)
    },[])
    return {value: input, onChange:handleChange}
}*/
/*
const useAllYears = (search) => {
    const q = React.useMemo(
        () => ({
            collection: "node",
            pipeline: [
                {
                    $match: {
                        type: { $in: ["document", "article", "page"] },
                        $text: {
                            $search: search
                        },
                    },
                },
                { $sort: { year: 1 } },
                { $project: { year: 1 } },
                {
                    $group: {
                        _id: "$year",
                        count: { $sum: 1 },
                    },
                },
                { $sort: { _id: -1 } },
            ],
        }),
        [search]
    )
    //console.log(q)
    return useQuery(q)
}
*/
const searchRemoveYear = search =>
      search
      ? Object.keys(search)
      .filter(key => key !== "year")
      .reduce((acc, key) => ({ ...acc, [key]: search[key] }), {})
      : null

const initYearFilter = history =>
      history.location.search.year ? history.location.search.year.split(",") : []

const YearFilter = ({ history, search, onChange }) => {
    //const [allYears] = useAllYears(search)
    const facets = useSolrFacets(search)
    const [years, setYears] = React.useState(() => initYearFilter(history))
    const handleYearToggle = React.useCallback(
        e => {
            const year = e.currentTarget.dataset.year
            let ys = years
            if (years.includes(year)) ys = years.filter(y => y !== year)
            else ys = [...years, year]
            if (ys.length === 0) {
                history.pushSearch({
                    ...pagerToFirstPage(searchRemoveYear(history.location.search)),
                })
            } else {
                history.pushSearch({ ...pagerToFirstPage(history.location.search), year: [ys] })
            }
            setYears(ys)
            onChange(ys)
        },
        [years, history, onChange]
    )
    if (!facets?.year) return null
    //console.log(years, facets)
    return (
        <div className="year-filter">
            {facets.year.buckets.map(bucket => (
                <div
                    key={bucket.val}
                    data-year={bucket.val}
                    onClick={handleYearToggle}
                >
                    <FaIcon
                        icon={
                            years.includes(`${bucket.val}`)
                                ? "toggle/check_box"
                                : "toggle/check_box_outline_blank"
                        }
                        size={24}
                    />
                    <div className="year">
                        {bucket.val}
                    </div>
                    <div className="count">({bucket.count})</div>
                </div>
            ))}
        </div>
    )
}
/*
const useData = state => {
    const { search, years, page, pageSize } = state

    let qYear = {}

    try {
        qYear =
            years && years.length > 0
                ? {
                      year: {
                          $in: flatten(
                              years.map(y =>
                                  y === "NaN" ? [NaN, null, undefined] : parseInt(y, 10)
                              )
                          ),
                      },
                  }
                : {}
    } catch (e) {
        console.log(e)
    }

    const q = React.useMemo(
        () => {
            if(!search) return null

        return  {
                      collection: "node",
                      query: {
                          type: { $in: ["document", "article", "page"] },
                          $text: {
                              $search: search
                          },
                          ...qYear,
                      },
                      projection: {
                          title: 1,
                          subtitle: 1,
                          body: 1,
                          thumb: 1,
                          thumb_front: 1,
                          type: 1,
                          path: 1,
                          date: 1,
                          showTime: 1,
                          state: 1,
                          docs: 1,
                          cat: 1,
                          _e: 1,
                          //...(cat.hasperson ? { person: 1, year: 1 } : {}),
                      },
                      limit: pageSize,
                      skip: (page - 1) * pageSize,
                      sort: { date: -1, _id: -1 },
        }
        },
        [search, qYear, page, pageSize]
    )
    //console.log(q)
    return useQuery(q)
}*/


const initState = (history) => {
    const search = history.location.search
    const page = parseInt(search && search.page ? search.page : "1", 10)
    const input = (search && search.search)?search.search:""
    const pageSize = 10
    return {
        search: normalize(input),
        input,
        years: search.year ? search.year.split(",") : [],
        page,
        pageSize,
    }
}

const SearchInput = ({value, onChange}) => {
    const [focus, setFocus] = React.useState()
    const handleFocus = React.useCallback(() => {
        setFocus(true)
    }, [])
    const handleBlur = React.useCallback(() => {
        setFocus(false)
    }, [])
    const args = {}
    if(!value) args["is-empty"] = ""
    if(focus) args["is-focus"] = ""
    return (
        <div className="sp-search-form-field" {...args}>
            <label htmlFor="sp-search">Caută documente...</label>
            <input id="sp-search" type="search" name="sp-search" value={value} onChange={onChange} onFocus={handleFocus} onBlur={handleBlur}/>
        </div>
    )
}

const Search = ({ domRef, info, value, children, ...props }) => {
    const history = useHistory()
    const [state, setState] = React.useState(() => initState(history))
    //const res = useSearch(state.input, "document")
    //console.log(res)
    const handleInputChange = React.useCallback(e => {
        const input = e.target.value
        setState(state => ({...state, input, search: normalize(input), page:1}))
    },[])
    const handleYearChange = React.useCallback(years => {
        setState(state => ({ ...state, years }))
    }, [])
    const handlePageChange = React.useCallback(page => {
        setState(state => ({ ...state, page }))
    }, [])
    const [data, status, total] = useSolr(state)
    //console.log(data, status, total)
    return (
        <div ref={domRef} {...props}>
            <SearchInput value={state.input} onChange={handleInputChange}/>
            {status==="loading" && <p>Se încarcă...</p>}
            {status==="loaded" && (
                <p>
                    {total===0 && "Nici un rezultat."}
                    {total===1 && "Un rezultat."}
                    {total>1 && `Rezultate: ${(state.page-1)*state.pageSize+1}-${Math.min(state.page*state.pageSize, total)} din ${total} rezultate.`}
                </p>
            )
            }
            {state.input && data && (
                <>
                    <YearFilter history={history} search={state.input} onChange={handleYearChange} />
                    <Pager
                page={state.page}
                total={total}
                pageSize={state.pageSize}
                onChange={handlePageChange}
            />

            <div className="view-content">
                {data && data.map((e, i) => (
                    <EntityView info={info} entity={e} key={i} className="row" display="teaser" />
                ))}
            </div>
            <Pager
                page={state.page}
                total={total}
                pageSize={state.pageSize}
                onChange={handlePageChange}
            /></>)}
        </div>
    )
}
export default React.memo(Search)
