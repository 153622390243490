import React, { useState, useRef, useCallback, useMemo } from "react"
import C from "../conf"
import renderers from "./renderers"
import { useSubscriptionProvider, useAccessibility } from "../hooks"
import { useIntersection } from "../intersection"
import Delayed from "./Delayed"
import Entity from "../entity"
/*
const wrapCond = (cond, Wrapper, props, wrapped) => {
    if (!cond) return wrapped
    return <Wrapper {...props}>{wrapped}</Wrapper>
    }*/
const sanitize = s => s.replace("/", "")
const getRenderer = fieldInfo =>
    fieldInfo
        ? fieldInfo.renderer
            ? renderers[fieldInfo.renderer]
            : renderers[fieldInfo.typeName] ||
              renderers[fieldInfo.name] ||
              (fieldInfo.type !== fieldInfo.typeName
                  ? getRenderer(Entity.types[fieldInfo.type])
                  : null)
        : null

const Field = props => {
    const {
        domRef,
        info,
        field,
        tag,
        className,
        noadmin,
        rendererComp,
        renderer,
        _nowrap,
        _editParent,
        showEmpty,
        children,
        ...other
    } = props
    const localRef = useRef()
    const localDomRef = domRef || localRef
    const mounted = useRef(true)
    const visible = useRef()
    const parents = useRef()
    const [localState, localSetState] = useState()
    const a11y = useAccessibility()
    //console.log(a11y)
    const localInfo = useMemo(() => {
        if (!info) {
            console.log(`Field ${field} has no INFO.`)
            return {}
        }
        //console.log(info)
        const { user, entity, entityInfo, language } = info
        const parent = info.value
        const parentInfo = info.fieldInfo
        const fieldName = info.fieldName ? `${info.fieldName}.${field}` : field
        //console.log(fieldName, field, parent, parentInfo)
        let fieldInfo = parent
            ? Entity.getTypeInfo(field, parent, parentInfo)
            : Entity.getTypeInfo(fieldName, entity)

        //console.log(fieldName, fieldInfo)
        const displayInfo = Entity.getFieldDisplayInfo(entity, fieldInfo, info.display)
        /*const display = info.display || "default"
        if (
            entityInfo &&
            entityInfo.display &&
            entityInfo.display[display] &&
            entityInfo.display[display][fieldName]
        )
            fieldInfo = { ...fieldInfo, ...entityInfo.display[display][fieldName] }
*/
        //console.log(fieldName, parent, field, parentInfo, fieldInfo)
        let rawValue = parent
            ? Entity.get(parent, field, parentInfo, language)
            : Entity.get(entity, fieldName)
        //let rawValue = Entity.get(entity, fieldName, language)
        //console.log(fieldName, rawValue)
        if (rawValue === undefined && fieldInfo && fieldInfo.default) rawValue = fieldInfo.default
        const value =
            fieldInfo && fieldInfo.prepareView ? fieldInfo.prepareView(rawValue) : rawValue
        const states = fieldInfo ? fieldInfo.states || info.states : info.states
        const state =
            fieldInfo && (fieldInfo.states || fieldInfo.stateful) ? localState : info.state
        const setState =
            fieldInfo && (fieldInfo.states || fieldInfo.stateful) ? localSetState : info.setState
        if (user && !noadmin && entity) {
            if (_editParent) {
                parents.current = [...(info.parents ? info.parents.current : [])]
                if (parents.current.length > 0)
                    parents.current[parents.current.length - 1] = Object.assign(
                        {},
                        parents.current[parents.current.length - 1],
                        { domRef: localDomRef }
                    )
            } else
                parents.current = [
                    ...(info.parents ? info.parents.current : []),
                    {
                        domRef: localDomRef,
                        entity,
                        entityInfo,
                        fieldInfo,
                        field: fieldName,
                        states,
                        state,
                        setState,
                    },
                ]
        }
        //console.log(field, parent, parentFieldInfo, fieldInfo, conf, value)
        return {
            domRef: localDomRef,
            user,
            entity,
            entityInfo,
            displayInfo,
            language,
            fieldName,
            field,
            fieldInfo,
            value,
            parents,
            states,
            state,
            setState,
        }
    }, [info, localState, localDomRef, noadmin, _editParent, field])
    const { user, entity, fieldInfo, displayInfo, fieldName, value } = localInfo
    //console.log("RENDER FIELD", field, value)
    const setFieldAdmin = useRef(useSubscriptionProvider("fieldAdmin"))

    const mouseOver = useRef(false)
    const onMouseOver = useCallback(() => {
        //console.log("mouse over", parents.current)
        if (mouseOver.current) return

        mouseOver.current = true
        //console.log(parents.current)
        setFieldAdmin.current({ mouse: "enter", fieldLine: parents.current })
    }, [])

    const onMouseLeave = useCallback(() => {
        //console.log("mouse leave")
        window.requestAnimationFrame(() => {
            mouseOver.current = false
            setFieldAdmin.current({ mouse: "leave", fieldLine: parents.current })
        })
    }, [])
    //console.log(field, conf, confLocal)
    //console.log(language, entity, field, fieldInfoLocal, val)
    const Renderer =
        props.renderer || fieldInfo
            ? fieldInfo.renderer
                ? renderers[fieldInfo.renderer]
                : renderers[fieldInfo.typeName] ||
                  renderers[fieldInfo.name] ||
                  getRenderer(Entity.types[fieldInfo.typeName])
            : null

    const onIntersect = useCallback(
        entry => {
            if (!mounted.current) return
            if (fieldInfo.onIntersect) fieldInfo.onIntersect(entry)

            if (!fieldInfo._visibility || visible.current || !entry.isIntersecting) return
            visible.current = true
            localDomRef.current.classList.add(
                fieldInfo && fieldInfo.classVisible ? fieldInfo.classVisible : "visible"
            )
            //setVisible(true)
        },
        [visible, fieldInfo, localDomRef]
    )
    //console.log(fieldInfo, Renderer)
    useIntersection(
        fieldInfo && (fieldInfo._visibility || fieldInfo.onIntersect) && Renderer
            ? localDomRef
            : null,
        onIntersect
    )
    const fa11y = fieldInfo?.a11y
    const onAudio = React.useCallback(() => {
        //console.log("audio")
        if (fa11y) document.getElementById("a11y-audio").src = `${C.BASE}/audio/${fa11y}.mp3`
    }, [fa11y])
    //console.log(fieldName, value)
    //console.log(field, fieldInfo, displayInfo, value)
    if (fieldInfo && fieldInfo._hidden && !user) return null
    if (displayInfo === false) return null
    //if (value === undefined) return null
    if (!showEmpty && fieldInfo && fieldInfo.isEmpty && fieldInfo.isEmpty(value)) return null
    if (!Renderer) {
        console.log("Renderer not found for ", field, fieldInfo, displayInfo, value, entity)
        return null
    }
    //console.log(fieldInfo, value)
    //if (info.states) console.log(info.state, conf)
    //if (info.states) console.log(info.state, info.states, fieldInfo)
    if (
        info.states &&
        fieldInfo.state &&
        fieldInfo.state.indexOf(info.state || info.states[0].val) < 0
    )
        return null

    //const entityConf = entity ? entity._conf || {} : {}
    //const fieldConf = conf || entity ? entity.getConf(field) || {} : {}
    //console.log(field, fieldConf)

    const childFields =
        value && value._e
            ? value._o
                ? value._o
                      .split(",")
                      .filter(f => Object.keys(value._e).indexOf(f) >= 0 && !!value._e[f].type)
                : Object.keys(value._e).filter(f => !!value._e[f].type)
            : []
    //console.log(field, fieldInfo, value, childFields)
    //? Object.keys(entityConf).filter(f => entityConf[f] && entityConf[f]._block === field)
    //: []

    const wrapperProps = {}
    //console.log(field, fieldInfoLocal)
    if (fieldInfo.id) wrapperProps.id = fieldInfo.id
    /*
    let classes =
        `field ${field.split(".").pop()} field-${fieldInfo.typeName || fieldInfo.type} ` +
        (fieldInfo._class ? fieldInfo._class : "") +
        (className || "")
    */
    //let classes = className || ''
    wrapperProps[
        `field-${fieldName
            .toLowerCase()
            .split(".")
            .pop()}`
    ] = ""
    wrapperProps[`type-${sanitize((fieldInfo.typeName || fieldInfo.type).toLowerCase())}`] = ""
    //if (visible) classes += " field-visible"

    let classes = []
    if (className) classes.push(className)
    if (fieldInfo._class) {
        classes.push(fieldInfo._class)
        //console.log(localInfo, fieldInfo)
    }
    if (visible.current) classes.push(fieldInfo.classVisible || "visible")
    if (classes.length > 0) wrapperProps.className = classes.join(" ")
    //if (data && user) {
    //console.log("add  mouse", user && !noadmin && entity)
    if (user && !noadmin && entity) {
        //console.log("add  mouse")
        //if (!parents.current)
        //setupLineage()
        wrapperProps.onMouseOver = onMouseOver
        //wrapperProps.onMouseEnter = onMouseOver
        wrapperProps.onMouseLeave = onMouseLeave
    }
    if (fieldInfo.ga) {
        if (!wrapperProps.style) wrapperProps.style = {}
        wrapperProps.style.gridArea = fieldInfo.ga
    }
    if (a11y && a11y.audio && fieldInfo.a11y) {
        wrapperProps.onClick = onAudio
    }
    const renderField = props => {
        const Before = displayInfo.before
        const After = displayInfo.after
        //console.log(Renderer, value)
        return children || childFields.length > 0 ? (
            <Renderer {...props} info={localInfo} value={value}>
                {Before && <Before entity={entity} field={field} value={value} />}
                {children}
                {childFields &&
                    childFields.map((f, i) => <Field key={i} info={localInfo} field={f} />)}
                {After && <After entity={entity} field={field} value={value} />}
            </Renderer>
        ) : (
            <>
                {Before && <Before entity={entity} field={field} value={value} />}
                <Renderer {...props} info={localInfo} value={value} />
                {After && <After entity={entity} field={field} value={value} />}
            </>
        )
    }
    //console.log(fieldInfo)
    if (fieldInfo.delayed) {
        if (_nowrap || fieldInfo._nowrap) {
            return (
                <Delayed maxDelay={fieldInfo.maxDelay}>
                    {renderField({
                        ...wrapperProps,
                        ...other,
                        domRef: localDomRef,
                    })}
                </Delayed>
            )
        }
        const Tag = tag || displayInfo.tag || "div"
        if (fieldInfo._inner2) {
            return (
                <Delayed maxDelay={fieldInfo.maxDelay}>
                    <Tag ref={localDomRef} has-inner2="" {...wrapperProps}>
                        <div inner="">
                            <div inner2="">
                                {fieldInfo && fieldInfo.pre && fieldInfo.pre()}
                                {renderField(other)}
                            </div>
                        </div>
                    </Tag>
                </Delayed>
            )
        }
        if (fieldInfo._inner) {
            return (
                <Delayed maxDelay={fieldInfo.maxDelay}>
                    <Tag ref={localDomRef} has-inner="" {...wrapperProps}>
                        <div inner="">
                            {fieldInfo && fieldInfo.pre && fieldInfo.pre()}
                            {renderField(other)}
                        </div>
                    </Tag>
                </Delayed>
            )
        }
        return (
            <Delayed maxDelay={fieldInfo.maxDelay}>
                <Tag ref={localDomRef} {...wrapperProps}>
                    {fieldInfo && fieldInfo.pre && fieldInfo.pre()}
                    {renderField(other)}
                </Tag>
            </Delayed>
        )
    }

    //console.log("field", localInfo.fieldName, value)
    //console.log(fieldInfo, _nowrap)
    if (_nowrap || fieldInfo._nowrap) {
        return renderField({
            ...wrapperProps,
            ...other,
            domRef: localDomRef,
        })
    }
    const Tag = tag || displayInfo.tag || "div"
    //console.log(Tag)
    if (fieldInfo._inner2) {
        return (
            <Tag ref={localDomRef} has-inner2="" {...wrapperProps}>
                <div inner="">
                    <div inner2="">
                        {fieldInfo && fieldInfo.pre && fieldInfo.pre()}
                        {renderField(other)}
                    </div>
                </div>
            </Tag>
        )
    }
    if (fieldInfo._inner) {
        return (
            <Tag ref={localDomRef} has-inner="" {...wrapperProps}>
                <div inner="">
                    {fieldInfo && fieldInfo.pre && fieldInfo.pre()}
                    {renderField(other)}
                </div>
            </Tag>
        )
    }
    //console.log(wrapperProps)
    return (
        <Tag ref={localDomRef} {...wrapperProps}>
            {fieldInfo && fieldInfo.pre && fieldInfo.pre()}
            {renderField(other)}
        </Tag>
    )
}

export default React.memo(Field)
