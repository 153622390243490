const customNodes = {
    "/cautare": {
        //pattern: /.* /,
        _id: {},
        _custom: true,
        title: "Căutare",
        type: "page",
        path: "/cautare",
        f0:{},
        _e: {f0: {name:"f0", type:"search"}}
    },
}
export default customNodes
